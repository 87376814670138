import React from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import * as SeasonalityActions from '../../store/seasonality'
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler'
import testIds from '../../testIds'


export default connect(state => ({
  downloadPermitted: state.seasonality.downloadPermitted // enabled when data has been fetched - prevents duplicated fetches
}), {
  fetchSeasonalityData: SeasonalityActions.fetchSeasonalityData
})(({
  fetchSeasonalityData, extraAction, downloadPermitted, disabled
}) =>

  <Button
    variant="contained"
    color="info"
    onClick={() => {
      if (extraAction)
        extraAction()
      callWithErrorHandler(fetchSeasonalityData)
    }}
    data-testid={testIds.Seasonality[1]}
    disabled={downloadPermitted || disabled}
  >
    Fetch
  </Button>
)