import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Tooltip from '../Tooltips/Tooltip'
import styles from './Tables.module.scss'
import { spreadStatsResponseFields, spreadStatsTableFields } from '../../constants'
import testIds from '../../testIds'


const SpreadStats = ({ spreadStats, seasonality = false }) => {

  if (!spreadStats) return null

  const testidprefix = (seasonality ? testIds.SeasonalityPrefixes[8] : testIds.AnnualRecommendationPrefixes[14])

  const { t } = useTranslation()

  return <div className={styles.container} data-testid={testidprefix + testIds.Table[0]}>
    <table className={styles.table}>

      <thead>
        <tr>
          {
            Object.keys(spreadStatsTableFields).map((field, index) =>
              <th className={styles.headerCell} key={'headers' + index}>
                <Tooltip content={t(`Tooltips.spreadStats.${spreadStatsTableFields[field]}`)}>
                  {field}
                </Tooltip>
              </th>
            )
          }
        </tr>
      </thead>

      <tbody>
        <tr>
          {
            spreadStatsResponseFields.map((field, index) =>
              <td className={styles.bodyCell} key={field + index} data-testid={testidprefix + testIds.Table[1] + '-' + field}>
                {spreadStats[field]}
              </td>
            )
          }
        </tr>
      </tbody>

    </table>
  </div>
}

export default connect(state => ({
  spreadStats: state.seasonality.spreadStats,
  seasonality: true
}))(SpreadStats)


export const SpreadStats_Annual = connect(state => ({
  spreadStats: state.annualRecommendation.seasonality?.spreadStats
}))(SpreadStats)