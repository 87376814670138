import React, { useState } from 'react'
import styles from './Help.module.scss'
import Modal from '@mui/material/Modal'
import { Close } from '@mui/icons-material'
import helpPages from './HelpPages'


export default () => {

  const [pageNumber, setPageNumber] = useState(0)

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setPageNumber(0)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)


  return <>
    <div className={styles.helpContainer} onClick={handleOpen}>
      Help
    </div>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disablePortal={true}
    >
      <div className={styles.modalContainer}>
        <div className={styles.close} onClick={handleClose}>
          <Close fontSize={'large'} />
        </div>
        <h1 style={{ textAlign: 'center' }}>{helpPages[pageNumber].title}</h1>
        {
          helpPages[pageNumber].content
        }
        <div className={styles.quickLinks}>
          {
            helpPages.map(
              ({ title }, index) =>
                <div
                  className={styles.link}
                  style={pageNumber == index ? { color: 'black', textDecoration: 'underline', cursor: 'default' } : {}}
                  onClick={() => setPageNumber(index)}
                  key={index + title + 'omfg heres a unique key'}
                >
                  {title}
                </div>
            )
          }
        </div>
      </div>
    </Modal>
  </>
}
