import React from 'react'
import { connect } from 'react-redux'
import * as SeasonalityActions from '../../../store/seasonality'
import styles from './TimeParameters.module.scss'
import YearPicker from '../../YearPicker/YearPicker'
import testIds from '../../../testIds'


export default connect(state => ({
  startYear: state.seasonality.startYear,
  excludeYear: state.seasonality.excludeYear,
  calendarMonth: state.seasonality.calendarMonth,
  maxDate: state.seasonality.maxDate
}), {
  setStartYear: SeasonalityActions.setStartYear,
  setExcludeYear: SeasonalityActions.setExcludeYear,
})(({
  startYear, excludeYear, maxDate, calendarMonth,
  setStartYear, setExcludeYear
}) =>
  <div className={styles.root}>

    <div className={styles.row}>
      <div className={styles.item}>
        <div>Start year</div>
        <div>
          <YearPicker
            year={startYear}
            yearOnChange={setStartYear}
            testid={testIds.Seasonality[3]}
          />
        </div>
      </div>

      <div className={styles.item}>
        <div>Max Date</div>
        <div style={{ marginLeft: 10 }} data-testid={testIds.Seasonality[4]}>
          {maxDate}
        </div>
      </div>
    </div>

    <div className={styles.row}>
      <div className={styles.item}>
        <div>Exclude year</div>
        <div style={{ marginLeft: 10 }}>
          <YearPicker
            nullable
            year={excludeYear}
            yearOnChange={setExcludeYear}
            testid={testIds.Seasonality[5]}
          />
        </div>
      </div>

      <div className={styles.item}>
        <div>Calendar Month</div>
        <div data-testid={testIds.Seasonality[6]}>
          {calendarMonth}
        </div>
      </div>
    </div>

  </div>
)