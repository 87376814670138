import React from 'react'
import { connect } from 'react-redux'
import Textbox from '../../Textbox/Textbox'
import Tooltip from '../../Tooltips/Tooltip'
import styles from './ProductParameters.module.scss'
import * as SeasonalityActions from '../../../store/seasonality'
import SearchableDropdown from '../../SearchableDropdown/SearchableDropdown'
import { getCalendarMonths, getProductList } from '../../../store/selector'
import testIds from '../../../testIds'


export default connect(state => ({
  productList: getProductList(state),
  calendarMonths: getCalendarMonths(state),
  selectedProducts: state.seasonality.selectedProducts
}), {
  setProduct: SeasonalityActions.setProduct,
  setExchangeMonthForProduct: SeasonalityActions.setExchangeMonthForProduct,
  setLegYearForProduct: SeasonalityActions.setLegYearForProduct,
  setLegWeightForProduct: SeasonalityActions.setLegWeightForProduct
})(({
  productList, calendarMonths, selectedProducts,
  setProduct,
  setLegYearForProduct,
  setExchangeMonthForProduct,
  setLegWeightForProduct,
  rowIndex
}) =>
  <tr key={'productRow' + rowIndex}>

    <td className={styles.bodyCell}>
      <div className={styles.cellContainer}>
        <SearchableDropdown
          testidprefix={testIds.SeasonalityPrefixes[2] + rowIndex + '-'}
          list={productList}
          handleChange={val => setProduct(val, rowIndex)}
          selectedValue={selectedProducts[rowIndex].value}
          searchable
        />
        <Tooltip content={`${selectedProducts[rowIndex].value} - ${selectedProducts[rowIndex].units}`} />
      </div>
    </td>

    <td className={styles.bodyCell}>
      <div className={styles.cellContainer}>
        <SearchableDropdown
          testidprefix={testIds.SeasonalityPrefixes[3] + rowIndex + '-'}
          list={calendarMonths}
          handleChange={val => setExchangeMonthForProduct(val, rowIndex)}
          selectedValue={selectedProducts[rowIndex].month}
        />
      </div>
    </td>

    <td className={styles.bodyCell}>
      <div className={styles.cellContainer}>
        <SearchableDropdown
          testidprefix={testIds.SeasonalityPrefixes[4] + rowIndex + '-'}
          handleChange={val => setLegYearForProduct(val, rowIndex)}
          list={selectedProducts[rowIndex].legYearList}
          selectedValue={selectedProducts[rowIndex].legYear}
        />
      </div>
    </td>

    <td className={styles.bodyCell}>
      <div className={styles.cellContainer}>
        <Textbox
          testidprefix={testIds.SeasonalityPrefixes[5] + rowIndex}
          value={selectedProducts[rowIndex].legWeight}
          handleChange={val => setLegWeightForProduct(val, rowIndex)}
        />
      </div>
    </td>

    <td className={styles.bodyCell}
      data-testid={testIds.SeasonalityPrefixes[6] + rowIndex}>
      {selectedProducts[rowIndex].marketViewCode + (selectedProducts[rowIndex].month ? selectedProducts[rowIndex].month[0] : '')}
    </td>

    <td className={styles.bodyCell}
      data-testid={testIds.SeasonalityPrefixes[7] + rowIndex}>
      <div className={styles.cellContainer}>
        {selectedProducts[rowIndex].lastUpdated ? new Date(selectedProducts[rowIndex].lastUpdated).toISOString().split('T')[0] : 'unknown'}
      </div>
    </td>

  </tr>
)