//



const loadingReasons = Object.freeze({
  init: 'init',
  fetchAnnualModelResults: 'fetchAnnualModelResults',
  fetchSeasonalityData: 'fetchSeasonalityData',
  setParamsFromURL: 'setParamsFromURL',
  selectModelResponseRow: 'selectModelResponseRow',
  calculatePriceMove: 'calculatePriceMove'
})

const defaultAnnualRecommendationTableConfig = Object.freeze({
  "detailedLabel": {
    "headerText": "Trade Label",
    "sortable": false,
    "sortkey": "detailedLabel",
    "visible": true
  },
  "Trade_Type": {
    "headerText": "Trade Type",
    "sortable": false,
    "sortkey": "Trade_Type",
    "visible": true
  },
  "Mo_Up_Pct": {
    "headerText": "Bull Trade Probability",
    "sortable": true,
    "sortkey": "Mo_Up_Pct",
    "visible": true
  },
  "trade_up_sort": {
    "headerText": "Expected Up Move",
    "sortable": true,
    "sortkey": "trade_up_sort",
    "visible": true
  },
  "Mo_Dn_Pct": {
    "headerText": "Bear Trade Probability",
    "sortable": true,
    "sortkey": "Mo_Dn_Pct",
    "visible": true
  },
  "trade_dn_sort": {
    "headerText": "Expected Down Move",
    "sortable": true,
    "sortkey": "trade_dn_sort",
    "visible": true
  },
  "Mo_Wtd_Move": {
    "headerText": "Probability Weighted Move",
    "sortable": true,
    "sortkey": "Mo_Wtd_Move",
    "visible": true
  },
  "abs_trade_value": {
    "headerText": "Absolute Expected Move",
    "sortable": true,
    "sortkey": "abs_trade_value",
    "visible": true
  },
  "abs_trade_value_USDBBL": {
    "headerText": "Absolute Expected Move (USD/BBL)",
    "sortable": true,
    "sortkey": "abs_trade_value_USDBBL",
    "visible": true
  },
  "pl_year_DaysToExpiry": {
    "headerText": "Days Until Expiry",
    "sortable": true,
    "sortkey": "pl_year_DaysToExpiry",
    "visible": true
  },
  "median": {
    "headerText": "median",
    "sortable": true,
    "sortkey": "median",
    "visible": true
  },
  "mu": {
    "headerText": "mu",
    "sortable": true,
    "sortkey": "mu",
    "visible": true
  },
  "sigma": {
    "headerText": "sigma",
    "sortable": true,
    "sortkey": "sigma",
    "visible": true
  },
  "min": {
    "headerText": "min",
    "sortable": true,
    "sortkey": "min",
    "visible": true
  },
  "max": {
    "headerText": "max",
    "sortable": true,
    "sortkey": "max",
    "visible": true
  }
})


const spreadStatsTableFields = Object.freeze({
  'Current Value': 'currentValue',
  'Local Avg': 'localAvg',
  'Global Avg': 'globalAvg',
  'Local Min': 'localMin',
  'Global Min': 'globalMin',
  'Local Max': 'localMax',
  'Global Max': 'globalMax',
  'Local z-Score': 'localZScore',
  'Global z-score': 'globalZScore',
  'Avg Next Month': 'avgNextMonth'
})

const spreadStatsResponseFields = Object.freeze([
  'CurrentValue',
  'LocalAvg',
  'GlobalAvg',
  'LocalMin',
  'GlobalMin',
  'LocalMax',
  'GlobalMax',
  'LocalZScore',
  'GlobalZScore',
  'AvgNextMonthMove'
])


const monthlyProfileFields = Object.freeze(['Avg', 'Min', 'Max', 'Std.Dev'])

const routes = Object.freeze({
  'Seasonality': '/',
  'Annual Recommendation': '/annual',
  'queryParams': '/powerbi',
  'Daily Email Preferences': '/outliers'
})


module.exports = {
  defaultAnnualRecommendationTableConfig,
  loadingReasons,
  spreadStatsResponseFields,
  spreadStatsTableFields,
  monthlyProfileFields,
  routes
}