import React, { useState, useRef, useEffect } from 'react'
import styles from './Tooltip.module.scss'
import Portal from '../Portal/Portal'

// wrap an element to make it have a tooltip on hover
const OFFSET = 20
const TOOLTIP_COLOR = '#DFE0E1'

const PositionedArrow = ({ position }) =>
  <div
    style={{
      position: 'absolute',
      [position]: '100%',
      left: '50%',
      borderWidth: 8,
      marginLeft: -4,
      borderStyle: 'solid',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      borderTopColor: position == 'top' ? TOOLTIP_COLOR : 'transparent',
      borderBottomColor: position == 'bottom' ? TOOLTIP_COLOR : 'transparent',
      cursor: 'inherit'
    }}
  />



export default ({
  children, content, position = 'top', disabled = false
}) => {
  const [isVisible, setVisible] = useState(false)


  const [coords, setCoords] = useState({})
  const divref = useRef(null)

  const updateTooltipCoords = () => {
    const rect = divref.current.getBoundingClientRect()
    const extra = position == top ? OFFSET : -1 * OFFSET
    setCoords({
      left: rect.x + rect.width / 2, // add half the width of the element for centering
      top: rect.y + window.scrollY - extra// add scrollY offset, as soon as getBountingClientRect takes on screen coords
    })
    setVisible(true)
  }

  useEffect(() => {
    setVisible(false)
  }, [content])

  return <div
    ref={divref}
    onMouseEnter={updateTooltipCoords}
    onMouseLeave={() => setVisible(false)}
    style={{ backgroundColor: 'transparent' }}
  >
    {
      children ?? <span className={styles.icon}>?</span>
    }
    {
      (!disabled && isVisible) &&
      <Portal>
        <div
          style={{
            position: 'absolute',
            ...coords,
            width: '250px',
            transform: 'translateX(-50%)',
            padding: 10,
            borderRadius: 10,
            boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.3)',
            backgroundColor: TOOLTIP_COLOR
          }}
        >
          {content}
          <PositionedArrow position={'bottom'} />
        </div>
      </Portal>
    }
  </div>

}