import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Scatter } from 'react-chartjs-2'
import SeasonalityTitle from './SeasonalityTitle'
import testIds from '../../testIds'


function leapYear(year) {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)
}

// TODO this exists elsewhere
const xLabels = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'June',
  6: 'Jul',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
  12: 'Jan'
}

// TODO this exists elsewhere
const colors = [
  "#EF4135", //Gradient Red **TEST***
  "#005596", //Suncor Blue
  "#FFC429", //Gradient Yellow
  "#F58220", //Gradient Orange
  "#66CBE1", //Sky
  "#7EC353", //Clover
  "#253746", //Midnight
  "#5B7C91", //Dusty Blue
  "#6A9377", //Moss
  "#626DA1", //Dusk
  "#E5C18A", //Sand
  "#DE751E", //Orange
  "#67737D", //Midnight 70%
  "#FECE70", //Gold 70%
  "#E89E61", //Orange 70%
  "#94DBEA", //Sky 70%
  "#A5D586", //Clover 70%
  "#8DA3B2", //Dusty Blue 70%
  "#EDD4AD", //Sand 70%
  "#BAE8F1", //Sky 40%
  "#C5E4B1", //Clover 40%
  "#B5C4CE", //Dusty Blue 40%
  "#F3E3CA", //Sand 40%
  "#FDB933", //Gold
  "#384147", //Dark Grey
  "#D1D3D4", //Light Grey
  "#7B8D97", //Slate
  "#747A7E", //Dark Grey 70%
  "#DFE0E1", //Light Grey 70%
  "#97B3A0", //Moss 70%
  "#9199BD", //Dusk 70%
  "#A3AFB6", //Slate 70%
  "#FEDFA3", //Gold 40%
  "#F0C19A", //Orange 40%
  "#AFB3B5", //Dark Grey 40%
  "#EAEBEC", //Light Grey 40%
  "#9DA5AC", //Midnight 40%
  "#BCCEC2", //Moss 40%
  "#B9BDD5", //Disk 40%
  "#C4CCD0"  //Slate 40%
]



const SeasonalityGraph = ({ chartData, unit, seasonality = false }) => {
  if (!chartData) return null
  const [spreadGraphData, setSpreadGraphData] = useState(undefined)

  useEffect(() => { // what is this for?
    if (chartData.length > 0) {
      for (let i = chartData.length - 1; i >= 0; i--) {
        if (chartData[i].y == null) {
          // console.log('does this ever happen? TODO')
          chartData[i].y = 2000 // = null
        }
      }
      setSpreadGraphData(chartData)

    }
  }, [chartData])


  const dynamicDatasets = []

  const myChart = {  //dataValues
    type: 'scatter',
    datasets: dynamicDatasets
  }

  if (spreadGraphData) {
    let index = 1
    let maxYear = 0
    let lineWidth = 1

    spreadGraphData.forEach(currValue => {
      if ((currValue != null) && (currValue['year'] > maxYear)) {
        maxYear = currValue['year']
      }
    })

    spreadGraphData.forEach(currValue => {
      if (currValue != null) {
        let name = currValue['year']
        let color

        if (name == maxYear) {
          color = colors[0]
          lineWidth = 3
        }
        else {
          color = colors[index % colors.length]
        }

        dynamicDatasets.push({
          backgroundColor: 'white',
          pointRadius: 0,
          borderColor: color,
          showLine: true,
          fill: 'none',
          label: name,
          borderWidth: lineWidth,
          data: currValue['points']
        })
        index = index + 1
      }
    })
  }


  const options = {
    plugins: {

      title: {
        display: false
      },

      legend: {
        display: true
      },

      tooltip: {
        callbacks: {
          // label: (tooltipItem, _data) => {
          label: ({raw, datasetIndex}) => {
            const dayOfYear = raw.x
            const dataSeriesIndex = datasetIndex
            const dataSeriesYear = spreadGraphData[dataSeriesIndex]['year']
            
            const spreadValue = spreadGraphData[dataSeriesIndex]['points'].find(point => point['x'] == dayOfYear)['y']

            const year = (dayOfYear > 366) ? dataSeriesYear : dataSeriesYear - 1

            /* 
                            _______________________________________ 
                            / Be careful changing this. It is quite \
                            \ complex.                              /
                            --------------------------------------- 
                              \         ,        ,
                               \       /(        )`
                                \      \ \___   / |
                                       /- _  `-/  '
                                      (/\/ \ \   /\
                                      / /   | `    \
                                      O O   ) /    |
                                      `-^--'`<     '
                                      (_.)  _  )   /
                                      `.___/`    /
                                        `-----' /
                            <----.     __ / __   \
                            <----|====O)))==) \) /====
                            <----'    `--' `.__,' \
                                        |        |
                                          \       /
                                    ______( (_  / \______
                                  ,'  ,-----'   |        \
                                  `--{__________)        \/

            RULE: We always reserve the 60th day of the year for February 29, regardless of whether or not it is a leap year. 
            Reason: We are graphing time series data across a number of years which may or may not contain a leap year. 

            There are 3 possible conditions: 
              - non leap year / non leap year
              - leap year / non leap year
              - not leap year / leap year

            Important date ranges: 
              -     1 - 59
              -    60 - 366 
              -   367 - 425
              -   426 - end

            Dates to check: 
            - January 1 (Day 1)
            - February 29 (Day 60)
            - March 1 (Day 61)
            - December 31 (Day 366)
            - January 1 (Day 367)
            - February 29 (Day 426)
            - March 1 (Day 427)
            
            */

            let baseDate = new Date(year, 0)
            let dayToSet

            switch (true) {
              case ((dayOfYear >= 1) && (dayOfYear <= 59)): {//console.log('1 - 59::YEAR:', year) // correct; no leap year interference until the 60th day
                dayToSet = dayOfYear
                break
              }
              case ((dayOfYear >= 60) && (dayOfYear <= 366)): {// console.log('60 - 366::YEAR:', year) // is it a leap year ? correct : -1 day
                dayToSet = leapYear(year) ? dayOfYear : dayOfYear - 1
                break
              }
              case ((dayOfYear >= 367) && (dayOfYear <= 425)): {// console.log('367 - 425::YEAR:', year) // prev. leap year ? correct : - 1 day
                if (leapYear(year - 1)) {
                  dayToSet = dayOfYear - 1
                }
                else {
                  if (leapYear(year)) {
                    dayToSet = dayOfYear
                  }
                  else dayToSet = dayOfYear - 1
                }
                break
              }
              case (dayOfYear >= 426): {// console.log('426 - ∞::YEAR:', year) // is it a leap year ? - 1 days : - 2 days
                if (leapYear(year - 1)) {
                  dayToSet = dayOfYear - 2
                }
                else {
                  if (leapYear(year)) {
                    dayToSet = dayOfYear - 1
                  }
                  else dayToSet = dayOfYear - 2
                }
                break
              }
            }

            baseDate = new Date(baseDate.setDate(dayToSet))
            const day = baseDate.getDate()
            const month = baseDate.getMonth()

            return xLabels[month] + ' ' + day + ', ' + year + ' (' + dayOfYear + '): ' + spreadValue
          }
        }
      }
    },

    responsive: true,
    spanGaps: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          // what the heck is this magic number?!?!
          // ((365 * 3) + 366) / 48 = 30.4375
          // ((365.2425 * 3) + 366) / 48 = 30.45265625
          stepSize: 30.43685, // googling gives: https://converter.ninja/time/months-to-days/63-mo-to-d/ but what is the significance of 63 months? It's not obvious.
          autoSkip: true,
          callback: (value, _index, _values) => xLabels[Math.floor(value / 30 % 12)]
        },
        grid: {
          display: true,
          drawBorder: true
        }
      },
      y: {
        title: {
          display: true,
          text: unit
        },
        beginAtZero: true,
        ticks: {
          max: 10,
          autoSkip: true
        }
      }
    }
  }

  const testidprefix = seasonality ? testIds.SeasonalityPrefixes[10] : testIds.AnnualRecommendationPrefixes[8]

  return <div style={{ position: 'relative', width: '100%', height: 350, }} id="graph" data-testid={testidprefix + testIds.Chart[0]}>
    <SeasonalityTitle seasonality={seasonality} testidprefix={testidprefix} />
    <Scatter options={options} data={myChart} />
  </div>

}


export default connect(state => ({
  chartData: JSON.parse(JSON.stringify(state.seasonality.chartData)),
  unit: state.seasonality.selectedUnit,
  seasonality: true // yes, this is a little hacky but ¯\_(ツ)_/¯
}))(SeasonalityGraph)


export const SeasonalityGraph_Annual = connect(state => ({
  chartData: JSON.parse(JSON.stringify(state.annualRecommendation.seasonality?.chartData ?? { cool: true })),
  unit: state.annualRecommendation.seasonality?.selectedUnit
}))(SeasonalityGraph)