import { createSelector } from 'reselect'
import _ from 'lodash'
import { makeChartTitle, intersectConvertableUnits } from './utils'


const _getSelectedProducts = state => Object.values(state.seasonality.selectedProducts)
const _getStructures = state => state.application.structures
const _getFormula = state => state.seasonality.selectedStructure?.formula ?? ''
const _getChartTitleIsLongForm = state => state.application.chartTitleIsLongForm
const _getMonths = state => state.application.calendarMonths
const _getProducts = state => state.application.products
const _getAnnualStructures = state => state.application.annualStructures
const _getModelResponse = state => state.annualRecommendation.response
const _getProfitLoss = state => state.annualRecommendation.profitLoss
const _getAnnualLegs = state => state.annualRecommendation.annualLegs
const _getFrontMonthExclusion = state => state.annualRecommendation.frontMonthExclusion
const _getProfitLossGranularity = state => state.annualRecommendation.profitLossGranularity

export const getAnnualStructureFormula = state => state.application.structures[state.annualRecommendation.annualStructure].formula


export const getConvertableUnits = createSelector(
  [_getSelectedProducts],
  intersectConvertableUnits
)

export const getStructureList = createSelector(
  [_getStructures],
  structures => Object.keys(structures)
)

export const getCalendarMonths = createSelector(
  [_getMonths],
  months => Object.keys(months)
)

export const getProductList = createSelector(
  [_getProducts],
  products => Object.keys(products)
)

export const getModelStructureList = createSelector(
  [_getAnnualStructures],
  annualStructures => Object.keys(annualStructures)
)

export const getUniqueYears = createSelector(
  [_getModelResponse],
  response => {
    if (!response || !response.length) return []
    return Array.from(new Set(response.map(val => val.CalYear))).sort((a, b) => a - b)
  })


export const getCurrentRecommendationsFromResponse = createSelector(
  [_getModelResponse, _getFrontMonthExclusion],
  (response, frontMonthExclusion) => {
    const currentYear = new Date().getFullYear()
    // pl_year_DaysToExpiry
    return response?.length
      ? response.filter(({ CalYear, pl_year_DaysToExpiry }) => CalYear == (currentYear - 1) && (frontMonthExclusion ? pl_year_DaysToExpiry > 31 : true))
      : []
  }
)

export const getProfitLossYears = createSelector(
  [_getProfitLoss],
  profitLoss => [...new Set(profitLoss.map(row => row.PLYear))].sort((a, b) => a - b)
)

export const getProfitLoss = createSelector(
  [_getProfitLoss, _getProfitLossGranularity, getProfitLossYears],
  (profitLoss, granularity, profitLossYears_all) => {
    if (!profitLoss) return null
    let xAxis = profitLossYears_all
    if (granularity === 'Yearly') {
      xAxis = [...new Set(profitLoss.map(row => row.PLYear))].sort((a, b) => a - b)
    }
    if (granularity === 'Monthly') {
      xAxis = [...new Set(profitLoss.map(row => row.CalMonth))].sort((a, b) => a - b) // these are currently [1,2,3..12], maybe row=>calendarMonths[row.CalMonth]
    }

    const response = {}
    xAxis.forEach(x => { response[x] = { profit: 0, loss: 0 } })

    for (const { PLYear: year, CalMonth: month, ActualMove: actual, P_L } of profitLoss) {
      const axisType = granularity === 'Yearly' ? year : month
      if (P_L === 'P')
        response[axisType].profit += Math.abs(actual)
      else
        response[axisType].loss += Math.abs(actual)
    }

    return response
  }
)



export const getProfit = createSelector(
  [getProfitLoss],
  profitLoss => profitLoss && Object.values(profitLoss).map(yearlyprofitLoss => yearlyprofitLoss.profit)
)


export const getLoss = createSelector(
  [getProfitLoss],
  profitLoss => profitLoss && Object.values(profitLoss).map(yearlyprofitLoss => yearlyprofitLoss.loss)
)


const _roundToTwoDecimalPlaces = num => Math.round((num + Number.EPSILON) * 100) / 100
const _sumObjectFieldsOfKey = values => _roundToTwoDecimalPlaces(values.reduce((prev, curr) => prev + curr, 0.00)).toFixed(2)

export const getTotalProfit = createSelector(
  [getProfit],
  profit => profit && _sumObjectFieldsOfKey(profit)
)


export const getTotalLoss = createSelector(
  [getLoss],
  loss => loss && _sumObjectFieldsOfKey(loss)
)

export const getAnnualRecommendationUnit = createSelector(
  [getCurrentRecommendationsFromResponse],
  recommendations => recommendations.length && recommendations[0].DisplayUnit
)

export const getAnnualLegsIncludesAnys = createSelector(
  [_getAnnualLegs],
  legs => ({ hasAnys: legs.includes('Any'), onlyAnys: legs.filter(leg => leg != 'Any').length == 0 })
)


//Replaces LegX with LEG_WEIGHT(ALIAS.MONTH)LEG_YEAR for each LegX 
export const getChartTitle = createSelector(
  [_getFormula, _getSelectedProducts, _getChartTitleIsLongForm],
  makeChartTitle
)

export const getThreeLetterMonths = createSelector(
  [getCalendarMonths],
  months => months.map(month => month.split('- ')[1])
)