import styles from './NotificationView.module.scss'
import sanitizeHTML from '../sanitizeHTML'

export default (message, type) => `
  <div class='${styles.toast} ${type ? styles[type] : ''}'>
   ${sanitizeHTML(message)}
   <div class='${styles.timestamp}'>
     ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', })}
   </div>
  </div>
`;
