import axios from 'axios'
import msalInstance from './auth/msalInstance'

export const isLocal = process.env.LOCAL || process.env.JEST_WORKER_ID//window.location.hostname.includes('localhost') || window.location.hostname.includes('cody.dev.lol')


if (!process.env.REACT_APP_API_URL?.length) {
  throw new Error('Missing the API URL. Unable to locate resource.')
}

const analyticsApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api/v1',
})


if (!isLocal)
  analyticsApi.interceptors.request.use(async (request) => {
    const accounts = msalInstance.getAllAccounts()

    const accessTokenRequest = {
      scopes: ["User.Read"],
      account: accounts[0],
    }

    try {
      const msalResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      )

      const { idToken, tokenType } = msalResponse

      if (idToken) request.headers.Authorization = `${tokenType} ${idToken}`
    } catch (error) {
      // Should not be used according to MS documentation
      // if (error instanceof InteractionRequiredAuthError) {
      //    instance.acquireTokenRedirect(accessTokenRequest)
      // }
      console.log('authorization token error', error)
    }
    return request
  })

export default analyticsApi