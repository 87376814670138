import analyticsApi from '../analyticsApi'
import metadata from '../default.json'
import getUserFromMSAL from '../auth/getUserFromMSAL'

// Metadata and stuff
const { Product_Structure_list: combinationMap } = metadata
const SEPARATOR = '&&&'


// Constants
const ENABLE_DAILY_OUTLIER_STRUCTURE = 'ENABLE_DAILY_OUTLIER_STRUCTURE' //     enable
const DISABLE_DAILY_OUTLIER_STRUCTURE = 'DISABLE_DAILY_OUTLIER_STRUCTURE'//    disable
const ADD_DAILY_OUTLIER_COMBINATION = 'ADD_DAILY_OUTLIER_COMBINATION'//        add
const REMOVE_DAILY_OUTLIER_COMBINATION = 'REMOVE_DAILY_OUTLIER_COMBINATION'//  remove
const REPLACE_DAILY_OUTLIER_PREFERENCES = 'REPLACE_DAILY_OUTLIER_PREFERENCES'//replace
const UPDATE_DAILY_OUTLIER_TERM = 'UPDATE_DAILY_OUTLIER_TERM'               //replace


// Helpers
const getInitialState = (existingPreferences = {}, term = 'short') => {
  const structures = Object.keys(combinationMap)
  const preferences = {}

  structures.forEach(structure => {
    // TODO any way this can be pulled out and tested? 
    if (!existingPreferences[structure])
      preferences[structure] = {
        enabled: false,
        combinations: {}
      }
    else preferences[structure] = {
      enabled: existingPreferences[structure].enabled, // aint this just true...?
      combinations: { ...existingPreferences[structure].combinations }
    }

    combinationMap[structure].Products.forEach(valueOrArray => {
      let combination

      switch (structure) {
        case 'Fly': {
          combination = valueOrArray + SEPARATOR + valueOrArray + SEPARATOR + valueOrArray
          break
        }
        case 'Time Spread':
        case 'Crack':
        case 'Inter-Product Spread': {
          const [leg1, leg2] = valueOrArray
          combination = leg1 + SEPARATOR + leg2
          break
        }
        case 'Box': {
          const [leg12, leg34] = valueOrArray
          combination = leg12 + SEPARATOR + leg12 + SEPARATOR + leg34 + SEPARATOR + leg34
          break
        }
      }
      if (!preferences[structure].combinations[combination])
        preferences[structure].combinations[combination] = false
    })

  })
  const state = { preferences, term }
  return state
}

const filterStructureCombinationMap = structureCombinationMap => {
  const subscriptionData = {}

  for (const structureKey in structureCombinationMap) {
    if (structureCombinationMap[structureKey].enabled) { // if structure is selected
      for (const combinationKey in structureCombinationMap[structureKey].combinations) {
        if (structureCombinationMap[structureKey].combinations[combinationKey]) { // if combination of products was selected
          if (!subscriptionData[structureKey])
            subscriptionData[structureKey] = [combinationKey]
          else subscriptionData[structureKey].push(combinationKey)
        }
      }
    }
  }

  return subscriptionData
}



// Reducer
export default (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case ENABLE_DAILY_OUTLIER_STRUCTURE: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [payload]: {
            ...state.preferences[payload],
            enabled: true
          }
        }
      }
    }
    case DISABLE_DAILY_OUTLIER_STRUCTURE: { // also sets all combinations to false
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [payload]: {
            ...state[payload],
            enabled: false,
            combinations: Object.fromEntries(Object.keys(state.preferences[payload].combinations).map((key) => [key, false]))
          }
        }
      }
    }
    case ADD_DAILY_OUTLIER_COMBINATION: {
      const { structure, productCombination } = payload
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [structure]: {
            ...state.preferences[structure],
            combinations: {
              ...state.preferences[structure].combinations,
              [productCombination]: true
            }
          }
        }
      }
    }
    case REMOVE_DAILY_OUTLIER_COMBINATION: {
      const { structure, productCombination } = payload
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [structure]: {
            ...state.preferences[structure],
            combinations: {
              ...state.preferences[structure].combinations,
              [productCombination]: false
            }
          }
        }
      }
    }
    case REPLACE_DAILY_OUTLIER_PREFERENCES: {
      return payload
    }
    case UPDATE_DAILY_OUTLIER_TERM: {
      return { ...state, term: payload }
    }
  }
  return state
}




// Actions
export const enableDailyOutlierStructure = structure => ({ type: ENABLE_DAILY_OUTLIER_STRUCTURE, payload: structure })
export const disableDailyOutlierStructure = structure => ({ type: DISABLE_DAILY_OUTLIER_STRUCTURE, payload: structure })
export const addDailyOutlierCombination = (structure, productCombination) => ({ type: ADD_DAILY_OUTLIER_COMBINATION, payload: { structure, productCombination } })
export const removeDailyOutlierCombination = (structure, productCombination) => ({ type: REMOVE_DAILY_OUTLIER_COMBINATION, payload: { structure, productCombination } })
export const replaceDailyOutlierPreferences = preferences => ({ type: REPLACE_DAILY_OUTLIER_PREFERENCES, payload: preferences })
export const updateDailyOutlierTerm = term => ({ type: UPDATE_DAILY_OUTLIER_TERM, payload: term })


const hydrateResponse = response => {
  const result = {}
  response.forEach(({ structure, combination }) => {
    if (!result[structure]) result[structure] = { enabled: true, combinations: { [combination]: true } }
    else result[structure].combinations[combination] = true
  })

  return result
}




export const subscribeToOutliers = () => async (dispatch, getState) => {
  const { dailyOutliers: { preferences, term } } = getState()
  const email = await getUserFromMSAL()

  const request = {
    email,
    preferences: filterStructureCombinationMap(preferences),
    term
  }

  const { preferences: _preferences } = (await analyticsApi.post('daily_outlier_subscribe', request)).data

  if (_preferences.length) {
    return true
  }
}



export const getUserDailyOutlierPreferences = () => async (dispatch, getState) => {
  const email = await getUserFromMSAL()

  const request = {
    email
  }

  const { preferences: _preferences, term } = (await analyticsApi.post('daily_outlier_subscription', request)).data

  const state = getInitialState(hydrateResponse(_preferences), term ?? 'short')
  dispatch(replaceDailyOutlierPreferences(state))

  return { fetched: true, preferences: state.preferences }
}


export const updateOutlierSubscription = () => async (dispatch, getState) => {
  const { dailyOutliers: { preferences, term } } = getState()
  const email = await getUserFromMSAL()

  const request = {
    email,
    preferences: filterStructureCombinationMap(preferences),
    term
  }

  const { preferences: _preferences } = (await analyticsApi.put('daily_outlier_subscribe', request)).data

  if (_preferences.length) {
    return true
  }
}




export const deleteSubscription = () => async (dispatch, getState) => {
  const email = await getUserFromMSAL()

  const request = {
    email
  }

  await analyticsApi.post('daily_outlier_unsubscribe', request)

  dispatch(
    replaceDailyOutlierPreferences(
      getInitialState()
    )
  )

  return true
}
