import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './SeasonalityGraph.module.scss'
import Tooltip from '../Tooltips/Tooltip'
import * as ApplicationActions from '../../store/application'
import { getChartTitle } from '../../store/selector'
import Expand from '@mui/icons-material/ZoomIn'
import Shrink from '@mui/icons-material/ZoomOut'
import testIds from '../../testIds'

const expandOrShrinkTooltipContent = isExpanded => <div>
  {isExpanded ? 'Shrink title to use alias codes' : 'Expand title to use full product names'}
</div>


const titleTooltipText = <div>
  <div>Each leg is encoded as: <br /><br />
    <div className={styles.bold}>Weight(Code.Month)Year</div>
  </div>
  <br />
  <div className={styles.note}>This title can be copied to share with others:
    <div className={styles.bold}>Right click {'>'} Copy link address</div>
  </div>
</div>


export default connect(state => ({
  chartTitle: getChartTitle(state),
  chartTitleIsLongForm: state.application.chartTitleIsLongForm,
  queryString_seasonality: state.seasonality.queryString,
  queryString_annual: state.annualRecommendation.seasonality?.queryString || ''
}), {
  toggleChartTitleLongForm: ApplicationActions.toggleChartTitleLongForm
})(({
  chartTitle,
  seasonality = false,
  queryString_seasonality, queryString_annual,
  chartTitleIsLongForm,
  toggleChartTitleLongForm,
  testidprefix
}) => {
  return <>
    <div className={styles.title}>
      <Tooltip content={expandOrShrinkTooltipContent(chartTitleIsLongForm)}>
        <div className={styles.expander} onClick={toggleChartTitleLongForm}>
          {chartTitleIsLongForm
            ?
            <Shrink sx={{ fontSize: 16, color: 'blue', cursor: 'pointer' }} />
            :
            <Expand sx={{ fontSize: 16, color: 'blue', cursor: 'pointer' }} />
          }
        </div>
      </Tooltip>
      <Link to={'/powerbi' + (seasonality ? queryString_seasonality : queryString_annual)} data-testid={testidprefix + testIds.Chart[1]}>
        {chartTitle}
      </Link>
      <div className={styles.tooltipContainer}><Tooltip content={titleTooltipText} /></div>
    </div>
  </>
})