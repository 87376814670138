import { Button } from '@mui/material'
import { connect } from 'react-redux'
import styles from '../DailyOutliers.module.scss'
import { SelectableCombinations as AnnualRecommendationCombinationsAccordion } from '../../../components/ExpandableList/AnnualRecommendationCombinationsAccordion'
import { labelValueMap, TimePreferenceSummary } from './ToggleTerm'


export default connect(state => ({
  preferences: state.dailyOutliers.preferences,
  term: state.dailyOutliers.term
}))(
  ({
    preferences,
    term,
    changePreferences,
    unSubscribe,
    testid
  }) =>
    <div className={styles.centeredColumn} data-testid={testid}>
      <h1>Daily Outlier Subscription Preferences</h1>
      <h2>These are your current preferences</h2>

      <TimePreferenceSummary
        label={labelValueMap[term]}
        currentValue={term}
      />

      <div className={styles.selectionsContainer}>

        <div className={styles.combinationSelections}>
          <AnnualRecommendationCombinationsAccordion structureMap={preferences} />
        </div>

      </div>

      <div className={styles.doubleButtonContainer}>
        <Button
          variant={'contained'}// | 'outlined' | 'text' | string
          children={'Unsubscribe'}
          color={'error'}
          onClick={unSubscribe}
          data-testid={testid + '-unsubscribe'}
        />
        <Button
          variant={'contained'}// | 'outlined' | 'text' | string
          children={'Change Preferences'}
          sx={{ marginLeft: 15 }}
          onClick={changePreferences}
          data-testid={testid + '-change'}
        />
      </div>

    </div>
)