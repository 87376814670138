import { Button } from '@mui/material'
import SuccessIcon from '@mui/icons-material/DoneAll'
import { useNavigate } from 'react-router-dom'
import styles from '../DailyOutliers.module.scss'


const successTextMap = {
  'Subscribe': {
    title: 'Subscription Successful!',
    middleText: 'Your subscription was successful',
    bottomText: 'You will now receive a daily email with the top 5 outliers per structure'
  },
  'Change': {
    title: 'Subscription Changed!',
    middleText: 'Your preferences have successfully changed',
    bottomText: 'Changes will be reflected in the next email'
  },
  'Unsubscribe': {
    title: 'Unsubscribe Successful!',
    middleText: 'You have successfully been unsubscribed',
    bottomText: 'You will no longer receive the Daily Outlier Email'
  },
}

export default ({ operation = 'Subscribe', testid }) => {
  const navigate = useNavigate()
  return <div className={styles.successContainer} data-testid={testid}>
    <div className={styles.centeredColumn}>
      <div className={styles.verticalEvenSpaced}>
        <h1>{successTextMap[operation].title}</h1>

        <div className={styles.successText}>
          <SuccessIcon color={'success'} sx={{ fontSize: 160 }} />
          <div style={{ marginLeft: 20 }}>
            <h3>{successTextMap[operation].middleText}</h3>
          </div>
        </div>

        <div>
          <h2>{successTextMap[operation].bottomText}</h2>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant={'contained'}// | 'outlined' | 'text' | string
            children={'Return to Seasonality'}
            color={'success'}
            onClick={() => { navigate('/') }}
            data-testid={testid + '-next'}
          />
        </div>
      </div>
    </div>
  </div>
}