import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import * as DailyOutlierActions from '../../store/dailyOutliers'

import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import Acknowledge from './Steps/Acknowledge'
import Preferences from './Steps/Preferences'
import ChangeOrUnsubscribe from './Steps/ChangeOrUnsubscribe'
import Confirm from './Steps/Confirm'
import Success from './Steps/Success'
import ToggleTerm from './Steps/ToggleTerm'

import testIds from '../../testIds'


export default connect(null, {
  subscribeToOutliers: DailyOutlierActions.subscribeToOutliers,
  updateOutlierSubscription: DailyOutlierActions.updateOutlierSubscription,
  deleteSubscription: DailyOutlierActions.deleteSubscription,
  getPreferences: DailyOutlierActions.getUserDailyOutlierPreferences
})(({
  subscribeToOutliers,
  updateOutlierSubscription,
  deleteSubscription,
  getPreferences
}) => {

  const [currentPage, setCurrentPage] = useState('Loading')
  const [preferencesFetched, setPreferencesFetched] = useState(false)

  useEffect(() => {
    (async () => {
      const { preferences: _preferences, fetched } = await getPreferences()
      setPreferencesFetched(fetched)
      const someStructuresEnabled = Object.values(_preferences).some(obj => obj.enabled)
      setCurrentPage(someStructuresEnabled ? 'Change' : 'Acknowledge')
    })()
  }, [])

  /*
    { // enter into "expression mode"
      (() => {  // IIFE - Immediately Invoked Function Expression
        switch (currentPage) { } // switch is a statement and not an expression, hence the need for an IIFE here. 
      })()
    }
  */
  // TODO add data-testid to a wrapper div
  return <LoadingIndicator ready={preferencesFetched} reason={'Checking for existing preferences...'} testid={testIds.Loading[11]}>
    <div data-testid={testIds.ApplicationPages[3]}>
      {
        (() => {
          switch (currentPage) {

            case 'Loading':
              return <LoadingIndicator
                ready={false}
              />

            /** 
              New User Subscription
            */
            case 'Acknowledge':
              return <Acknowledge
                nextPage={() => setCurrentPage('NewTerm')}
                testid={testIds.DailyOutliersPrefixes[0]}
              />

            case 'NewTerm':
              return <ToggleTerm
                previousPage={() => setCurrentPage('Acknowledge')}
                nextPage={() => setCurrentPage('Preferences')}
                testid={testIds.DailyOutliersPrefixes[1]}
              />

            case 'Preferences':
              return <Preferences
                previousPage={() => setCurrentPage('NewTerm')}
                nextPage={() => setCurrentPage('Confirm')}
                testid={testIds.DailyOutliersPrefixes[2]}
              />

            case 'Confirm':
              return <Confirm
                confirmationType={'new'}
                nextPage={async () => { setCurrentPage(await subscribeToOutliers() ? 'SuccessSubscribe' : 'Error') }}
                previousPage={() => { setCurrentPage('Preferences') }}
                testid={testIds.DailyOutliersPrefixes[3]}
              />

            case 'SuccessSubscribe':
              return <Success
                operation={'Subscribe'}
                testid={testIds.DailyOutliersPrefixes[4]}
              />

            /**
              Existing User
            */
            case 'Change':
              return <ChangeOrUnsubscribe
                changePreferences={() => { setCurrentPage('ChangeTerm') }}
                unSubscribe={() => { setCurrentPage('Unsubscribe') }}
                testid={testIds.DailyOutliersPrefixes[5]}
              />

            /**
              Existing User - Alter Subscription    (continued from case 'Change')
            */
            case 'ChangeTerm':
              return <ToggleTerm
                previousPage={() => setCurrentPage('Change')}
                nextPage={() => setCurrentPage('ChangePreferences')}
                testid={testIds.DailyOutliersPrefixes[6]}
              />

            case 'ChangePreferences':
              return <Preferences
                previousPage={() => setCurrentPage('ChangeTerm')}
                nextPage={() => setCurrentPage('ConfirmChanges')}
                testid={testIds.DailyOutliersPrefixes[7]}
              />

            case 'ConfirmChanges':
              return <Confirm
                confirmationType={'change'}
                nextPage={async () => { setCurrentPage(await updateOutlierSubscription() ? 'SuccessChange' : 'Error') }}
                previousPage={() => { setCurrentPage('ChangePreferences') }}
                testid={testIds.DailyOutliersPrefixes[8]}
              />

            case 'SuccessChange':
              return <Success
                operation={'Change'}
                testid={testIds.DailyOutliersPrefixes[9]}
              />

            /**
              Existing User - Unsubscribe           (continued from case 'Change')
            */
            case 'Unsubscribe':
              return <Confirm
                confirmationType={'unsubscribe'}
                nextPage={async () => { setCurrentPage(await deleteSubscription() ? 'SuccessUnsubscribe' : 'Error') }}
                previousPage={() => { setCurrentPage('Change') }}
                testid={testIds.DailyOutliersPrefixes[10]}
              />

            case 'SuccessUnsubscribe':
              return <Success
                operation={'Unsubscribe'}
                testid={testIds.DailyOutliersPrefixes[11]}
              />

            case 'Error':
              throw new Error('Something went wrong: Error Code 135')
          }
        })()
      }
    </div>
  </LoadingIndicator>


})
