import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'
import { connect } from 'react-redux'
import { SelectableCombinations } from '../../../components/ExpandableList/AnnualRecommendationCombinationsAccordion'
import styles from '../DailyOutliers.module.scss'
import { labelValueMap, TimePreferenceSummary } from './ToggleTerm'


const confirmTextMap = {
  'new': {
    'h1': 'Daily Outlier Confirmation of Selections',
    'h2': 'Confirm your selections to subscribe',
    'confirmButtonText': 'Subscribe'
  },
  'change': {
    'h1': 'Daily Outlier Confirmation of Selections',
    'h2': 'Confirm your changes to alter your subscription',
    'confirmButtonText': 'Alter Subscription'
  },
  'unsubscribe': {
    'h1': 'Daily Outlier Confirmation of Selections',
    'h2': 'You will no longer be a recipient of the Daily Outlier Email',
    'h4': 'Are you sure you want to unsubscribe?',
    'confirmButtonText': 'Yes, Unsubcribe'
  }
}


export default connect(state => ({
  preferences: state.dailyOutliers.preferences,
  term: state.dailyOutliers.term
}))(
  ({
    nextPage,
    preferences,
    previousPage,
    confirmationType,
    term,
    testid
  }) =>
    <div className={styles.centeredColumn} data-testid={testid}>
      <h1>{confirmTextMap[confirmationType].h1}</h1>
      <h2>{confirmTextMap[confirmationType].h2}</h2>


      {
        !confirmTextMap[confirmationType].h4 && // if h4 is not in text map, we are not unsubscribing - show the preferences
        <>

          <TimePreferenceSummary
            label={labelValueMap[term]}
            currentValue={term}
          />

          <div className={styles.selectionsContainer}>
            <div className={styles.combinationSelections}>
              <SelectableCombinations structureMap={preferences} testid={testid} />
            </div>
          </div>
        </>
      }

      {
        confirmTextMap[confirmationType].h4 &&
        <div className={styles.areYouSure}>
          <h4>{confirmTextMap[confirmationType].h4}</h4>
        </div>
      }

      <div className={styles.doubleButtonContainer}>
        <Button
          variant={'contained'}// | 'outlined' | 'text' | string
          color={'inherit'}
          children={<><ArrowBackIcon />Back</>}
          onClick={previousPage}
          data-testid={testid + '-back'}
        />
        <Button
          variant={'contained'}// | 'outlined' | 'text' | string
          children={confirmTextMap[confirmationType].confirmButtonText}
          sx={{ marginLeft: 15 }}
          onClick={nextPage}
          data-testid={testid + '-next'}
        />
      </div>

    </div>
)
