import { graphConfig } from './authConfig'
import msalInstance from './msalInstance'

const isTesting = process.env.JEST_WORKER_ID != undefined

const acquireAccessToken = async (msalInstance) => {
  if (isTesting) return;
  const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts()

  if (!activeAccount && accounts.length === 0) {
    /*
    * User is not signed in. Throw error or wait for user to login.
    * Do not attempt to log a user in outside of the context of MsalProvider
    */
    console.error('Error Code 72: No user found')
  }
  const request = {
    scopes: ['User.Read'],
    account: activeAccount || accounts[0]
  }

  const authResult = await msalInstance.acquireTokenSilent(request)

  return authResult.accessToken
}


/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
async function callMsGraph(accessToken) {
  if (isTesting) return;
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers
  }

  return fetch(graphConfig.graphMeEndpoint, options)
    .then(response => response.json())
    .catch(error => console.log(error))
}



export default async () => {
  if (isTesting) {
    console.warn('Jest worker ID detected. If you are not running tests and see this message, good luck. ')
    return 'cocorraini@suncor.com'
  }
  const accessToken = await acquireAccessToken(msalInstance)
  const graphResult = await callMsGraph(accessToken)
  return graphResult.mail
}