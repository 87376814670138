import analyticsApi from '../analyticsApi'
import { makeSeasonalityQueryString, seasonalityRequest, formatDate, intersectConvertableUnits } from './utils'
import { addLoadingReason, removeLoadingReason } from './application'
import { loadingReasons } from '../constants'
import _ from 'lodash'

// Constants
const SET_EXCLUDE_YEAR = 'SET_EXCLUDE_YEAR'
const SET_START_YEAR = 'SET_START_YEAR'

const SET_SELECTED_STRUCTURE = 'SET_SELECTED_STRUCTURE'
const SET_SELECTED_UNIT = 'SET_SELECTED_UNIT'
const SET_PRODUCT = 'SET_PRODUCT'
const SET_EXCHANGE_MONTH_FOR_PRODUCT = 'SET_EXCHANGE_MONTH_FOR_PRODUCT'
const SET_LEG_YEAR_LIST_FOR_PRODUCT = 'SET_LEG_YEAR_LIST_FOR_PRODUCT'
const SET_LEG_YEAR_FOR_PRODUCT = 'SET_LEG_YEAR_FOR_PRODUCT'
const SET_LEG_WEIGHT_FOR_PRODUCT = 'SET_LEG_WEIGHT_FOR_PRODUCT'
const SET_CHART_AND_TABLE_DATA = 'SET_CHART_AND_TABLE_DATA'

const SET_PRODUCTS_FROM_URL = 'SET_PRODUCTS_FROM_URL'
const SET_URL_PARAMETERS_ACCURATE = 'SET_URL_PARAMETERS_ACCURATE'

const CLEAR_PARAMETERS = 'CLEAR_PARAMETERS'

const PREFERRED_DEFAULT_UNIT = 'USD/BBL'


// Reducer
const getInitialState = () => ({
  urlParametersAccurate: false,

  maxDate: formatDate(new Date()),
  calendarMonth: new Date().getMonth() + 1,
  startYear: 2015,
  excludeYear: 0,
  selectedStructure: null,
  selectedUnit: '',
  selectedProducts: {},

  downloadPermitted: false,

  spreadStats: null,
  monthlyProfile: null,
  chartData: null,
  queryString: null
})


const emptyChartData = {
  spreadStats: null, monthlyProfile: null, chartData: null, urlParametersAccurate: false, downloadPermitted: false
}

export default (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case SET_START_YEAR: {
      return {
        ...state,
        startYear: payload,
        ...emptyChartData
      }
    }
    case SET_EXCLUDE_YEAR: {
      return {
        ...state,
        excludeYear: payload,
        ...emptyChartData
      }
    }
    case SET_PRODUCTS_FROM_URL: {
      return {
        ...state,
        selectedProducts: payload
      }
    }
    case SET_SELECTED_STRUCTURE: {
      return {
        ...state,
        selectedStructure: payload.selectedStructure,
        selectedProducts: payload.defaultProducts,
        selectedUnit: payload.selectedStructure.defaultValues.units,
        ...emptyChartData
      }
    }
    case SET_SELECTED_UNIT: {
      return {
        ...state,
        selectedUnit: payload,
        ...emptyChartData
      }
    }
    case SET_LEG_YEAR_LIST_FOR_PRODUCT: {
      state.products[payload.productToUpdate].legYearInfo = payload.legYearInfo
      return {
        ...state,
        ...emptyChartData
      }
    }
    case SET_PRODUCT: {
      const selectedProducts = JSON.parse(JSON.stringify(state.selectedProducts))
      selectedProducts[payload.rowIndex] = payload.newSelectedProduct
      const selectedUnit = payload.selectedUnit || state.selectedUnit
      return {
        ...state,
        selectedProducts,
        selectedUnit,
        ...emptyChartData
      }
    }
    case SET_LEG_YEAR_FOR_PRODUCT: {
      const selectedProducts = JSON.parse(JSON.stringify(state.selectedProducts))
      Object.values(selectedProducts)[payload.rowIndex].legYear = payload.legYear
      return {
        ...state,
        selectedProducts,
        ...emptyChartData
      }
    }
    case SET_EXCHANGE_MONTH_FOR_PRODUCT: {
      const selectedProducts = JSON.parse(JSON.stringify(state.selectedProducts))
      Object.values(selectedProducts)[payload.rowIndex].month = payload.month
      return {
        ...state,
        selectedProducts,
        ...emptyChartData
      }
    }
    case SET_LEG_WEIGHT_FOR_PRODUCT: {
      const selectedProducts = JSON.parse(JSON.stringify(state.selectedProducts))
      Object.values(selectedProducts)[payload.rowIndex].legWeight = payload.legWeight
      return {
        ...state,
        selectedProducts,
        ...emptyChartData
      }
    }
    case SET_CHART_AND_TABLE_DATA: {
      return {
        ...state,
        ...payload,
        downloadPermitted: true
      }
    }
    case SET_URL_PARAMETERS_ACCURATE: {
      return {
        ...state,
        urlParametersAccurate: payload
      }
    }
    case CLEAR_PARAMETERS: {
      return {
        ...state,
        selectedStructure: null,
        selectedProducts: {},
        selectedUnit: '',
        ...emptyChartData
      }
    }
  }
  return state
}

// Actions
export const clearParameters = () => ({ type: CLEAR_PARAMETERS })
export const setSelectedUnit = selectedUnit => ({ type: SET_SELECTED_UNIT, payload: selectedUnit })
export const setLegYearForProduct = (legYear, rowIndex) => ({ type: SET_LEG_YEAR_FOR_PRODUCT, payload: { legYear, rowIndex } })
export const setLegWeightForProduct = (legWeight, rowIndex) => ({ type: SET_LEG_WEIGHT_FOR_PRODUCT, payload: { legWeight, rowIndex } })

// TODO fetchLastProductUpdate
export const setExchangeMonthForProduct = (month, rowIndex) => ({ type: SET_EXCHANGE_MONTH_FOR_PRODUCT, payload: { month, rowIndex } })



export const setStartYear = year => dispatch => {
  const payload = year instanceof Date ? year.getFullYear() : year
  dispatch({ type: SET_START_YEAR, payload })
}

export const setExcludeYear = year => dispatch => {
  const payload = year instanceof Date ? year.getFullYear() : year
  dispatch({ type: SET_EXCLUDE_YEAR, payload })
}

export const fetchLegYearListForProduct = async productId => {
  const { data } = await analyticsApi.get(`/leg_year_list/${productId}`)
  return Array.from(data, (_, i) => i)
}

const fetchLastProductUpdate = async (productId, monthKey) => {
  const requestParams = {
    product_id: productId,
    contract_month: monthKey
  }
  const { data: { lastUpdated } } = await analyticsApi.post('/get_product_latest_update', requestParams)
  return lastUpdated
}

export const getStructureNameFromId = structureId => (dispatch, getState) => {
  const { application: { structures } } = getState()
  return Object.values(structures).find(({ key }) => structureId == key).value
}

export const setSelectedStructure = structureName => async (dispatch, getState) => {
  const { application: { structures, products, calendarMonths } } = getState()

  const selectedStructure = structures[structureName]
  const allProductsMap = new Map(Object.values(products).map(product => [product.key, product])) // allow O(1) access to product
  const allMonths = Object.values(calendarMonths) // no map because this will never grow, unless the earth takes longer to go around the sun

  const defaultProductPromises = []
  const defaultProducts = []
  selectedStructure.defaultValues.product.forEach((productId, legIndex) => {
    const product = { ...allProductsMap.get(productId) }
    product.legWeight = selectedStructure.defaultValues.legWeight[legIndex]
    product.legYear = selectedStructure.defaultValues.legYear[legIndex]
    if (!product.month)
      product.month = allMonths.find(month => month.key == selectedStructure.defaultValues.month[legIndex]).value
    if (!product.legYearList) {
      defaultProductPromises.push(
        fetchLegYearListForProduct(product.key)
          .then(legYearListForProduct => {
            product.legYearList = legYearListForProduct
          })
      )
    }
    if (!product.lastUpdated) {
      defaultProductPromises.push(
        fetchLastProductUpdate(product.key, selectedStructure.defaultValues.month[legIndex])
          .then(lastUpdatedForProduct => {
            product.lastUpdated = lastUpdatedForProduct
          })
      )
    }
    defaultProducts.push(product)
  })

  await Promise.all(defaultProductPromises)


  const payload = { selectedStructure, defaultProducts }
  dispatch({ type: SET_SELECTED_STRUCTURE, payload })
}


export const setProduct = (productName, rowIndex) => async (dispatch, getState) => {
  const { application: { products, calendarMonths } } = getState()
  const { seasonality: { selectedProducts } } = getState()
  const newSelectedProduct = { ...products[productName] }

  const payload = { rowIndex, newSelectedProduct }

  if (!payload.newSelectedProduct.month)
    payload.newSelectedProduct.month = selectedProducts[rowIndex].month
  if (!payload.newSelectedProduct.legWeight)
    payload.newSelectedProduct.legWeight = selectedProducts[rowIndex].legWeight

  const promises = []

  if (!newSelectedProduct.legYearList) {
    promises.push(
      fetchLegYearListForProduct(newSelectedProduct.key)
        .then(legYearList => {
          payload.newSelectedProduct.legYearList = legYearList
          if (newSelectedProduct.legYearList.includes(selectedProducts[rowIndex].legYear))
            payload.newSelectedProduct.legYear = selectedProducts[rowIndex].legYear
          else
            payload.newSelectedProduct.legYear = 0
        })
    )
  }

  if (!newSelectedProduct.lastUpdated) {
    promises.push(
      fetchLastProductUpdate(newSelectedProduct.key, calendarMonths[newSelectedProduct.month].key)
        .then(lastUpdated => {
          payload.newSelectedProduct.lastUpdated = lastUpdated
        })
    )
  }


  if (rowIndex == 0) {
    const convertableUnits = intersectConvertableUnits([...Object.values(selectedProducts).slice(1), newSelectedProduct])

    if (convertableUnits.includes(newSelectedProduct.units))
      payload.selectedUnit = newSelectedProduct.units
    else if (convertableUnits.includes(PREFERRED_DEFAULT_UNIT))
      payload.selectedUnit = PREFERRED_DEFAULT_UNIT
    else
      payload.selectedUnit = convertableUnits[0]
  }

  else {
    const convertableUnits = intersectConvertableUnits([Object.values(selectedProducts)[0], newSelectedProduct])

    if (convertableUnits.includes(selectedProducts[0].units))
      payload.selectedUnit = selectedProducts[0].units
    else if (convertableUnits.includes(newSelectedProduct.units))
      payload.selectedUnit = newSelectedProduct.units
    else
      payload.selectedUnit = convertableUnits[0]
  }

  if (promises.length)
    await Promise.all(promises)

  dispatch({ type: SET_PRODUCT, payload })
}





export const fetchSeasonalityData = () => async (dispatch, getState) => {
  dispatch(addLoadingReason(loadingReasons.fetchSeasonalityData, 'Fetching seasonality for selection...'))

  // TODO promises
  const payload = {
    queryString: makeSeasonalityQueryString(getState())
  }
  const promises = []
  const requestParams = seasonalityRequest(getState())

  promises.push(
    analyticsApi.post('spread_stats', requestParams)
      .then(apiResponse => {
        payload.spreadStats = apiResponse.data
      })
  )
  promises.push(
    analyticsApi.post('monthly_profile', requestParams)
      .then(apiResponse => {
        payload.monthlyProfile = apiResponse.data
      })
  )
  promises.push(
    analyticsApi.post('spread_by_timestamp', requestParams)
      .then(apiResponse => {
        payload.chartData = apiResponse.data
      })
  )

  await Promise.all(promises)


  dispatch({ type: SET_CHART_AND_TABLE_DATA, payload })
  dispatch(removeLoadingReason(loadingReasons.fetchSeasonalityData))
}


export const setParamsFromURL = urlParameters => async (dispatch, getState) => {
  dispatch(addLoadingReason(loadingReasons.setParamsFromURL, 'Fetching seasonality data based on URL...'))

  const allProducts = Object.values(getState().application.products)
  const allCalendarMonths = Object.values(getState().application.calendarMonths)

  const selectedProducts = await Promise.all(
    urlParameters['data'].map(async product => {

      const newSelectedProduct = {
        ...allProducts.find(p => p.key == product.productId),
        legYear: product.legYear,
        legWeight: product.legWeight
      }

      if (!newSelectedProduct.month)
        newSelectedProduct.month = allCalendarMonths.find(month => month.key == product.exchangeMonth).value
      if (!newSelectedProduct.legYearList)
        newSelectedProduct.legYearList = await fetchLegYearListForProduct(newSelectedProduct.key)
      if (!newSelectedProduct.lastUpdated)
        newSelectedProduct.lastUpdated = await fetchLastProductUpdate(newSelectedProduct.key, product.exchangeMonth)

      return newSelectedProduct
    })
  )

  const structureName = await dispatch(getStructureNameFromId(urlParameters['structureId']))

  // this cannot be done in bulk because async :(
  await dispatch(setExcludeYear(urlParameters['exclude_year']))
  await dispatch(setStartYear(urlParameters['start_year']))
  await dispatch(setSelectedStructure(structureName))
  await dispatch({ type: SET_PRODUCTS_FROM_URL, payload: selectedProducts })
  await dispatch(setSelectedUnit(urlParameters['unit']))


  await dispatch(fetchSeasonalityData())
  dispatch(({ type: SET_URL_PARAMETERS_ACCURATE, payload: true }))
  dispatch(removeLoadingReason(loadingReasons.setParamsFromURL))
}