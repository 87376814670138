import { configureStore } from '@reduxjs/toolkit'

import application from './application'
import seasonality from './seasonality'
import annualRecommendation from './annualRecommendation'
import dailyOutliers from './dailyOutliers'

export default configureStore({
  reducer: {
    application,
    seasonality,
    annualRecommendation,
    dailyOutliers
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 50,  },
    serializableCheck: {
      warnAfter: 50,
      ignoredPaths: ['annualRecommendation.abortControllers'],
      ignoredActionPaths: ['payload.annualModelAbortController', 'payload.profitLossAbortController']
    },
  })
})