export default props =>
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <style>{".cls-1{fill:#101820}"}</style>
    </defs>
    <title />
    <g data-name="Layer 10" id="Layer_10">
      <path
        className="cls-1"
        d="M17 31h-2a3 3 0 0 1-3-2.64 12.68 12.68 0 0 1-1.95-.8 3 3 0 0 1-4-.25L4.69 25.9a3 3 0 0 1-.25-4 12.68 12.68 0 0 1-.8-1.9A3 3 0 0 1 1 17v-2a3 3 0 0 1 2.64-3 12.68 12.68 0 0 1 .8-1.95 3 3 0 0 1 .25-4L6.1 4.69a3 3 0 0 1 4-.25 12.68 12.68 0 0 1 1.9-.8A3 3 0 0 1 15 1h2a3 3 0 0 1 3 2.64 12.68 12.68 0 0 1 1.95.8 3 3 0 0 1 4 .25l1.36 1.41a3 3 0 0 1 .25 4 12.68 12.68 0 0 1 .8 1.95A3 3 0 0 1 31 15v2a3 3 0 0 1-2.64 3 12.68 12.68 0 0 1-.8 1.95 3 3 0 0 1-.25 4l-1.41 1.36a3 3 0 0 1-4 .25 12.68 12.68 0 0 1-1.95.8A3 3 0 0 1 17 31Zm-7.09-5.67a.94.94 0 0 1 .51.14 11 11 0 0 0 2.83 1.17 1 1 0 0 1 .75 1V28a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.39a1 1 0 0 1 .75-1 11 11 0 0 0 2.83-1.17 1 1 0 0 1 1.21.15l.28.28a1 1 0 0 0 1.42 0l1.41-1.41a1 1 0 0 0 0-1.42l-.28-.28a1 1 0 0 1-.15-1.21 11 11 0 0 0 1.17-2.83 1 1 0 0 1 1-.75H28a1 1 0 0 0 1-1V15a1 1 0 0 0-1-1h-.39a1 1 0 0 1-1-.75 11 11 0 0 0-1.17-2.83 1 1 0 0 1 .15-1.21l.28-.28a1 1 0 0 0 0-1.42L24.49 6.1a1 1 0 0 0-1.42 0l-.28.28a1 1 0 0 1-1.21.15 11 11 0 0 0-2.83-1.17 1 1 0 0 1-.75-1V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.39a1 1 0 0 1-.75 1 11 11 0 0 0-2.83 1.17 1 1 0 0 1-1.21-.15l-.28-.31a1 1 0 0 0-1.42 0L6.1 7.51a1 1 0 0 0 0 1.42l.28.28a1 1 0 0 1 .15 1.21 11 11 0 0 0-1.17 2.83 1 1 0 0 1-1 .75H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.39a1 1 0 0 1 1 .75 11 11 0 0 0 1.17 2.83 1 1 0 0 1-.15 1.21l-.28.28a1 1 0 0 0 0 1.42l1.38 1.41a1 1 0 0 0 1.42 0l.28-.28a1 1 0 0 1 .7-.29Z"
      />
      <path
        className="cls-1"
        d="M16 23a7 7 0 1 1 7-7 7 7 0 0 1-7 7Zm0-12a5 5 0 1 0 5 5 5 5 0 0 0-5-5Z"
      />
    </g>
  </svg>