import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { constructObjectFromQueryString } from '../../store/utils'
import * as SeasonalityActions from '../../store/seasonality'
import { routes } from '../../constants'
import SeasonalityPage from './SeasonalityPage'
import testIds from '../../testIds'


export default connect(state => ({
  urlParametersAccurate: state.seasonality.urlParametersAccurate
}), {
  setParamsFromURL: SeasonalityActions.setParamsFromURL
})(({
  setParamsFromURL,
  urlParametersAccurate
}) => {

  const [searchParams, _setSearchParams] = useSearchParams()
  const [shouldReplaceUrlParameters, setShouldReplaceUrlParameters] = useState(false)

  // Example query parameters:
  // ?structureId=4&unit=USD/BBL&productId0=4&exchangeMonth0=1&legYear0=0&productId1=4&exchangeMonth1=2&legYear1=0&productId2=2&exchangeMonth2=1&legYear2=0&productId3=2&exchangeMonth3=2&legYear3=0

  // Example full local url:
  // http://localhost/powerbi?structureId=4&unit=USD/BBL&productId0=4&exchangeMonth0=1&legYear0=0&productId1=4&exchangeMonth1=2&legYear1=0&productId2=2&exchangeMonth2=1&legYear2=0&productId3=2&exchangeMonth3=2&legYear3=0&units=USD-BBL
  // http://localhost/powerbi?structureId=4&productId0=4&exchangeMonth0=1&legYear0=0&productId1=4&exchangeMonth1=2&legYear1=0&productId2=2&exchangeMonth2=1&legYear2=0&productId3=2&exchangeMonth3=2&legYear3=0
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setParamsFromURL(constructObjectFromQueryString(searchParams))
  }, [])

  useEffect(() => {
    if (!urlParametersAccurate && location.pathname != routes['Seasonality'] && shouldReplaceUrlParameters)
      navigate(routes['Seasonality'])
    if (!urlParametersAccurate)
      setShouldReplaceUrlParameters(true)
  }, [urlParametersAccurate])

  return <SeasonalityPage testId={testIds.ApplicationPages[1]} />

})

