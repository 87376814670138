const sea = 'seasonality-'
const ar = 'annual-recommendation-'
const dos = 'daily-outliers-'

module.exports = Object.freeze({
  ApplicationPages: [
    sea + 'page',
    sea + 'query-parameters',
    ar + 'page',
    dos + 'page'
  ],
  Header: ['header-container', 'header-last-updated'],
  NavigationSquares: [
    'navigation-squares',
    'navigation-popup',
    'navigation-seasonality-link',
    'navigation-annual-recommendation-link',
    'navigation-daily-outlier-link'
  ],
  Seasonality: [
    sea + 'formula',                // 0
    sea + 'fetch',                  // 1
    sea + 'clear',                  // 2
    sea + 'start-year',             // 3
    sea + 'max-date',               // 4
    sea + 'exclude-year',           // 5
    sea + 'calendar-month'          // 6
  ],
  SeasonalityPrefixes: [
    sea + 'structure-',             // 0
    sea + 'unit-',                  // 1
    sea + 'product-',               // 2
    sea + 'exchange-month-',        // 3
    sea + 'leg-year-',              // 4
    sea + 'leg-weight-',            // 5
    sea + 'spread-leg-',            // 6
    sea + 'product-last-updated-',  // 7
    sea + 'spread-stats-',          // 8
    sea + 'monthly-profile-',       // 9
    sea + 'graph-',                 // 10
  ],
  AnnualRecommendation: [
    ar + 'historical-price-move',
    ar + 'backtesting-graph'
  ],
  AnnualRecommendationTable: [ar + 'title', ar + 'row'],
  AnnualRecommendationPrefixes: [
    ar + 'month-',                       // 0
    ar + 'structure-',                   // 1
    ar + 'leg1-',                        // 2
    ar + 'leg2-',                        // 3
    ar + 'leg3-',                        // 4
    ar + 'leg4-',                        // 5
    ar + 'backtesting-total-profit-',    // 6
    ar + 'backtesting-total-loss-',      // 7
    ar + 'seasonality-graph-',           // 8
    ar + 'show-hide-',                   // 9
    ar + 'table-header-cell-',           // 10
    ar + 'table-data-cell-',             // 11
    ar + 'recommendations-',             // 12
    ar + 'seasonality-monthly-profile-', // 13
    ar + 'seasonality-spread-stats-',    // 14

  ],
  ProfitLossCard: ['profitloss-container', 'profitloss-total', 'profitloss-units'],
  SearchableDropdown: ['dropdown-container', 'dropdown-popover'],
  Table: ['table-container', 'table-field'],
  Chart: ['chart-container', 'chart-title'],
  Icon: ['icon-container', 'icon-option'],
  Loading: [
    'application-loading',                // 0
    ar + 'table-loading',                 // 1
    ar + 'price-move-loading',            // 2
    ar + 'profitloss-loading',            // 3
    ar + 'spread-stats-loading',          // 4
    ar + 'monthly-profile-loading',       // 5
    ar + 'seasonality-graph-loading',     // 6
    sea + 'parameters-loading',           // 7
    sea + 'spread-stats-loading',         // 8
    sea + 'monthly-profile-loading',      // 9
    sea + 'graph-loading',                //10
    dos + 'loading'                       //11
  ],
  DailyOutliersPrefixes: [
    // Passed to the stepper, these are general and can be added to inside of the component for more specific elements
    dos + 'acknowledge',                  //  0
    dos + 'NewTerm',                      //  1
    dos + 'Preferences',                  //  2
    dos + 'Confirm',                      //  3
    dos + 'SuccessSubscribe',             //  4
    dos + 'Change',                       //  5
    dos + 'ChangeTerm',                   //  6
    dos + 'ChangePreferences',            //  7
    dos + 'ConfirmChanges',               //  8
    dos + 'SuccessChange',                //  9
    dos + 'Unsubscribe',                  // 10
    dos + 'SuccessUnsubscribe',           // 11
  ]
})