import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './locales/en.json'
import ru from './locales/ru.json'
import zh from './locales/zh.json'
import ja from './locales/ja.json'

const resources = {
  en,
  ru,
  zh,
  ja,
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['cookie', 'localStorage'],
    },
    resources,
    fallbackLng: 'en',
    keySeparator: '.',
  })

export default i18n
