import React, { useState } from 'react'
import { connect } from 'react-redux'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import styles from './DownloadMenu.module.scss'
import { useTranslation } from 'react-i18next'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import html2canvas from "html2canvas"
import jsPdf from "jspdf"
import { seasonalityRequest } from '../../store/utils'
import analyticsApi from '../../analyticsApi'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Checkbox from '@mui/material/Checkbox'

const downloadFile = (response, name = 'unknown') => {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}



export default connect(state => ({
  state: state
}))(({
  state,
  openAccordion
}) => {

  const [includeSpreadStats, setIncludeSpreadStats] = useState(true)
  const [includeMonthlyProfile, setIncludeMonthlyProfile] = useState(true)
  const [includeGraphData, setIncludeGraphData] = useState(true)
  const [anchorEl, setAnchorEl] = useState(false)

  const disabled = !(includeSpreadStats || includeMonthlyProfile || includeGraphData)
  const { t } = useTranslation()

  const handleClose = () => setAnchorEl(null)
  const handleOpen = ({ currentTarget }) => {
    setAnchorEl(currentTarget)
    setIncludeSpreadStats(true)
    setIncludeMonthlyProfile(true)
    setIncludeGraphData(true)
  }

  const downloadPDF = async () => {
    openAccordion() // need this otherwise parameters are not included in the download (because the accorion contents is not visible)
    const domElement = document.getElementById("capture-for-download")
    /*
        NOTE: This is experimental but it removes the need for js2pdf
        to use this, uncomment it and comment out the block below it ( html2canvas().then() )

              const w = domElement.style.width
              const h = domElement.style.height
              // Set the width of canvas to twice the width of the container
              var canvas = document.createElement("canvas");
              canvas.width = w * 2;
              canvas.height = h * 2;
              canvas.style.width = w + "px";
              canvas.style.height = h + "px";
              var context = canvas.getContext("2d");
              // Then zoom the canvas and double the image onto the canvas.
              context.scale(2, 2);
              html2canvas(domElement, {
                allowTaint: false,
                taintTest: true,
                canvas: canvas,
                onrendered: function (canvas) {
                  canvas.id = "mycanvas"
                  canvas.style.display = 'none'
                  document.body.appendChild(canvas);
                  // Generating Base64 Picture Data
          
                  imgData = canvas.toDataURL("image/png", 1)
          
                  const _fixType = type => {
                    type = type.toLowerCase().replace(/jpg/i, 'jpeg')
                    const r = type.match(/png|jpeg|bmp|gif/)[0]
                    return 'image/' + r
                  }
                  // Processing image data, replacing MIME type
                  imgData = imgData.replace(_fixType(type), 'image/octet-stream')
          
                  const saveFile = function (data, filename) {
                    let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
                    save_link.href = data
                    save_link.download = filename
                    let event = document.createEvent('MouseEvents')
                    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
                    save_link.dispatchEvent(event)
                  };
          
                  // Question name after download
                  var filename = `Graph_${new Date().toISOString()}.pdf`.replace(/:/g, "-")
                  // download
                  saveFile(imgData, filename)
                },
                width: 1512,
                height: 15000
              })
      */

    html2canvas(domElement, { scale: 3 }).then(canvas => {
      const imgData = canvas.toDataURL("image/png", 1)
      const pdf = new jsPdf("p", "mm", "a4")
      const width = pdf.internal.pageSize.getWidth()
      const height = pdf.internal.pageSize.getHeight()
      pdf.addImage(imgData, "JPEG", 0, 0, width, height)
      pdf.save(`Graph_${new Date().toISOString()}.pdf`.replace(/:/g, "-"))
    })
  }

  const downloadSeasonalitySpreadsheet = async () => {
    const requestParams = seasonalityRequest(state)

    // TODO go through store/seasonality/download
    const resp = await analyticsApi({
      url: 'download',
      method: 'post',
      data: {
        ...requestParams,
        include_spread_stats: includeSpreadStats,
        include_graph_data: includeGraphData,
        include_monthly_profile: includeMonthlyProfile
      },
      responseType: 'blob'
    })

    let file_name = `Seasonality_Data_${new Date().toISOString()}.xlsx`.replace(/:/g, "-")
    downloadFile(resp, file_name)
    downloadPDF()
    handleClose()
  }

  const changeGraph = input => setIncludeGraphData(input.target.checked)
  const monthlyProfile = input => setIncludeMonthlyProfile(input.target.checked)
  const changeSpreadStats = input => setIncludeSpreadStats(input.target.checked)


  return state.seasonality.downloadPermitted && <div className={styles.container}>
    <Button onClick={handleOpen}
      variant="contained"
      sx={{
        maxHeight: 35,
        border: '1px solid black',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent'
        },
        color: 'black',
        textTransform: 'capitalize'
      }}
      endIcon={<FileDownloadIcon />}
    >
      <p style={{ fontFamily: 'NotoSans' }}>Download</p>
    </Button>

    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      <div className={styles.checkboxContainer}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={includeGraphData} onChange={changeGraph} inputProps={{ 'aria-label': 'controlled' }} />}
            label="Include graph"
          />
          <FormControlLabel
            control={<Checkbox checked={includeMonthlyProfile} onChange={monthlyProfile} inputProps={{ 'aria-label': 'controlled' }} />}
            label="Monthly profile"
          />
          <FormControlLabel
            control={<Checkbox checked={includeSpreadStats} onChange={changeSpreadStats} inputProps={{ 'aria-label': 'controlled' }} />}
            label="Spread stats"
          />
          <Button
            disabled={disabled}
            onClick={downloadSeasonalitySpreadsheet}
            variant="contained"
          >
            {t('DownloadMenu.submit')}
          </Button>
        </FormGroup>
      </div>
    </Popover>

  </div>
})