import { useNavigate, useLocation } from 'react-router-dom'
import { routes } from '../../constants'
import styles from './Link.module.scss'


export default ({ destination, beforeNavigating, testid = '', closePopover }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const weAreAtThisLocationAlready = pathname == routes[destination] || (pathname === '/powerbi' && routes[destination] === '/')

  const action = () => {
    if (!weAreAtThisLocationAlready) {
      if (beforeNavigating) beforeNavigating()
      if (closePopover) closePopover()
      navigate(routes[destination])
    }
  }

  return <div className={styles.link} onClick={action} data-testid={testid}>
    {`${weAreAtThisLocationAlready ? '>' : ' '} ${destination}`}
  </div>
}