import React from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { getProfitLoss, getTotalLoss, getTotalProfit, getProfitLossYears, getProfit, getLoss, getThreeLetterMonths } from '../../store/selector'
import styles from './ProfitLoss.module.scss'
import Tooltip from '../Tooltips/Tooltip'
import * as AnnualRecommendationActions from '../../store/annualRecommendation'

import Radio from '@mui/material/Radio'
import testIds from '../../testIds'

const PLunit = 'USD/BBL'

const TotalCard = ({ profit, unit, amount = 0.0, testidprefix }) =>
  <div className={profit ? styles.profit : styles.loss} data-testid={testidprefix + testIds.ProfitLossCard[0]}>
    <div className={styles.bold}>Total {profit ? 'Profit' : 'Loss'}</div>
    <div className={styles.small} data-testid={testidprefix + testIds.ProfitLossCard[1]}>({unit})</div>
    <div className={styles.bold} data-testid={testidprefix + testIds.ProfitLossCard[2]}>{amount}</div>
  </div>


const ToolTipContent = <div>
  <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Backtesting</div>
  The top recommendations from each year historically are backtested against its actual move to see if it would have been profitable.<br />
  <br />
  Backtesting is aggregated by the Structure and Product selections above (month is not considered).<br />
  <br />
  Each bar represents the total cumulative profit or loss for the corresponding year, if trades were made based on top recommendations<br />
</div>



export default connect(state => ({
  profitLoss: getProfitLoss(state),
  totalLoss: getTotalLoss(state),
  totalProfit: getTotalProfit(state),
  profit: getProfit(state),
  loss: getLoss(state),
  years: getProfitLossYears(state),
  structure: state.annualRecommendation.annualStructure,
  products: state.annualRecommendation.annualLegs,
  months: getThreeLetterMonths(state),
  profitLossGranularity: state.annualRecommendation.profitLossGranularity
}), {
  setProfitLossGranularity: AnnualRecommendationActions.setProfitLossGranularity
})(({
  profitLoss,
  loss,
  totalLoss,
  profit,
  totalProfit,
  years,
  structure,
  products,
  months,
  profitLossGranularity,
  setProfitLossGranularity
}) => {

  if (!profitLoss) return null

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    categoryPercentage: 0.8,
    barPercentage: 1.0,
    elements: {
      bar: {
        borderWidth: 1
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          align: 'center',
          text: profitLossGranularity === 'Yearly' ? 'Years' : 'Months'
        }
      },
      y: {
        title: {
          display: true,
          align: 'end',
          text: 'Cumulative Profit/Loss'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: `${structure} - ${products.join(', ')}`
      }
    }
  }

  const data = {
    labels: profitLossGranularity === 'Yearly' ? years : months,
    datasets: [
      {
        label: 'Loss',
        data: loss,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: '#ED6E8540',
      },
      {
        label: 'Profit',
        data: profit,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: '#54A1E540',
      },
    ],
  }

  return <>
    <div style={{ position: 'absolute', top: 10, left: 10 }}>
      <Tooltip content={ToolTipContent} />
    </div>
    <div className={styles.titleText}>
      Backtested Performance of Top Recommended Trades
    </div>
    <div className={styles.totals}>
      <TotalCard testidprefix={testIds.AnnualRecommendationPrefixes[6]} profit amount={totalProfit} unit={PLunit} />
      <TotalCard testidprefix={testIds.AnnualRecommendationPrefixes[7]} amount={totalLoss} unit={PLunit} />
    </div>
    <div data-testid={testIds.AnnualRecommendation[1]}>
      <Bar options={options} data={data} />
    </div>

    <div className={styles.dropdowns}>
      <div>
        Yearly
        <Radio
          checked={profitLossGranularity === 'Yearly'}
          onChange={e => setProfitLossGranularity(e.target.value)}
          value='Yearly'
          name='radio-buttons'
          inputProps={{ 'aria-label': 'Yearly' }}
        />
      </div>
      <div>
        Monthly
        <Radio
          checked={profitLossGranularity === 'Monthly'}
          onChange={e => setProfitLossGranularity(e.target.value)}
          value='Monthly'
          name='radio-buttons'
          inputProps={{ 'aria-label': 'Monthly' }}
        />
      </div>
    </div>
  </>

})