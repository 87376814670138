import _ from 'lodash'

export const seasonalityRequest = state => {
  const {
    seasonality: { selectedUnit, selectedStructure, selectedProducts, calendarMonth, startYear, excludeYear },
    application: { calendarMonths }
  } = state

  const seasonalityData = selectedProducts.map(({ key, month, legYear, legWeight: conversionFactor }) => {
    return {
      productId: key,
      exchangeMonth: calendarMonths[month].key,
      legYear,
      conversionFactor,
    }
  })

  return {
    cal_month: calendarMonth,
    exclude_year: excludeYear,
    start_year: startYear,
    structure: selectedStructure.value,
    unit: selectedUnit,
    data: seasonalityData
  }
}



export const annualRecommendationRequest = state => {
  const { annualRecommendation: { annualMonth, annualStructure, annualLegs }, application: { calendarMonths } } = state
  return {
    calendar_month: calendarMonths[annualMonth].key,
    structure: annualStructure,
    products: annualLegs
  }
}



export const parseQueryString = querystring => {
  // credit: https://attacomsian.com/blog/javascript-convert-query-string-to-object
  // parse query string
  const params = new URLSearchParams(querystring)

  const obj = {
    get: function (member) { return this[member] }
  }

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1)
      obj[key] = params.getAll(key)
    else
      obj[key] = params.get(key)
  }

  return obj
}



export const constructObjectFromQueryString = queryString => {

  const obj = {
    "exclude_year": 0, // never exclude since annual doesn't exclude
    "start_year": 2015, // always starts from 2015
    "unit": queryString.get("unit") ?? "USD/BBL", // default to USD/BBL
    "structureId": queryString.get("structureId").replace(/"/g, ''),
    "data": []
  }

  for (let i = 0; i < 4; i++) {
    let productId = queryString.get("productId" + i)
    let exchangeMonth = queryString.get("exchangeMonth" + i)
    let legYear = queryString.get("legYear" + i)

    if (productId == 'None' || productId == 0) productId = null
    if (exchangeMonth == 'None' || exchangeMonth == 0) exchangeMonth = null
    if (legYear == 'None') legYear = null


    if (productId && exchangeMonth && legYear)
      obj.data.push({
        productId, exchangeMonth, legYear, legWeight: 1
      })
  }

  return obj
}


const _queryItem = (key, value) => `&${key}=${value}`

export const makeSeasonalityQueryString = state => {
  const {
    seasonality: { selectedStructure: { key: structureId }, selectedProducts, selectedUnit },
    application: { calendarMonths }
  } = state

  // URL: ?structureId=4&units=USD/BBL&productId0=4&exchangeMonth0=1&legYear0=0&productId1=4&exchangeMonth1=2&legYear1=0&productId2=2&exchangeMonth2=1&legYear2=0&productId3=2&exchangeMonth3=2&legYear3=0

  let queryString = `?${_queryItem('structureId', structureId).substring(1)}${_queryItem('unit', selectedUnit)}`
  for (let i = 0; i < 4; i++) {
    const { key, legYear, month } = selectedProducts[i] ?? { key: '', month: '', legYear: '' }
    queryString += _queryItem('productId' + i, key)
    queryString += _queryItem('exchangeMonth' + i, calendarMonths[month]?.key)
    queryString += _queryItem('legYear' + i, legYear)
  }
  return queryString
}

export const formatDate = date => {
  let d = new Date(date)
  let month = (d.getMonth() + 1)
  let day = d.getDate()
  let year = d.getFullYear()

  if (month.length < 2)
    month = '0' + month
  if (day.length < 2)
    day = '0' + day

  return [year, month, day].join('-')
}

export const makeChartTitle = (formula, selectedProductsWithMonthAndWeight, isLongForm) => {
  let title = formula
  selectedProductsWithMonthAndWeight.forEach(({ legWeight, legYear, month, alias, value }, index) => {
    const thisReplace = new RegExp('Leg' + (+index + 1))
    const displayWeight = legWeight == 1 ? '' : legWeight
    const productOrAlias = isLongForm ? value : alias
    const thisValue = `${displayWeight}(${productOrAlias}.${month[0]})${legYear}`
    title = title.replace(thisReplace, thisValue)
  })
  // title = title.replaceAll('-', '—') // could replace with EM dash to make it more obvious? 
  // — <-- em dash 
  // - <-- n dash 
  return title
}

export const makeChartTitleFromRecommendation = (recommendedTrade, formula, allProducts) => {
  const { relative_label_interpreted, Product1, Product2, Product3, Product4 } = recommendedTrade
  // 4-K0/X0/H1
  const products = [Product1, Product2, Product3, Product4]
  const legMonthYearParts = relative_label_interpreted.split('-')[1].split('/')
  const selectedProductsWithMonthAndWeight = legMonthYearParts.map((monthYearString, index) => ({
    month: monthYearString[0],
    legYear: monthYearString[1],
    ...allProducts[products[index]],
    legWeight: 1 // Could also check for legWeight existence in makeChartTitle when determining displayWeight: legWeight ?? 1 == 1 ? '' : legWeight
  }))
  return relative_label_interpreted.slice(0, 2) + makeChartTitle(formula, selectedProductsWithMonthAndWeight, false)
}



export const intersectConvertableUnits = selectedProducts => {
  const convertableUnitArraysFromSelectedProducts = [...selectedProducts.map(({ convertableUnits }) => convertableUnits.split(','))]
  if (!convertableUnitArraysFromSelectedProducts.length) return []
  return _.intersection(...convertableUnitArraysFromSelectedProducts)
  const intersectedUnits = _.intersection(...convertableUnitArraysFromSelectedProducts)
  return [...new Set(intersectedUnits)]
}