import React from 'react'
import { connect } from 'react-redux'
import styles from './StructureParameters.module.scss'
import ClearParamsButton from '../../Buttons/ClearParamsButton'
import FetchDataButton from '../../Buttons/FetchDataButton'
import * as SeasonalityActions from '../../../store/seasonality'
import SearchableDropdown from '../../SearchableDropdown/SearchableDropdown'
import { getConvertableUnits } from '../../../store/selector'
import testIds from '../../../testIds'


export default connect(state => ({
  structures: state.application.structures,
  selectedStructure: state.seasonality.selectedStructure,
  units: getConvertableUnits(state),
  selectedUnit: state.seasonality.selectedUnit
}), {
  setSelectedStructure: SeasonalityActions.setSelectedStructure,
  setSelectedUnit: SeasonalityActions.setSelectedUnit
})(({
  structures, selectedStructure, units, selectedUnit,
  setSelectedStructure, setSelectedUnit,
  closeAccordion
}) => <div className={styles.container}>

    <div className={styles.group}>
      <SearchableDropdown
        testidprefix={testIds.SeasonalityPrefixes[0]}
        selectedValue={selectedStructure?.value}
        list={Object.keys(structures) || []}
        handleChange={setSelectedStructure}
      />
      {selectedStructure?.formula &&
        <>
          <h4 className={styles.formula} data-testid={testIds.Seasonality[0]}>{selectedStructure.formula}</h4>
          <SearchableDropdown
            testidprefix={testIds.SeasonalityPrefixes[1]}
            selectedValue={selectedUnit}
            list={units}
            handleChange={setSelectedUnit}
          />
        </>
      }
    </div>

    <div className={styles.buttonGroup}>
      {selectedStructure?.formula &&
        <>
          <div style={{ margin: 10 }}><ClearParamsButton /></div>
          <div style={{ margin: 10 }}><FetchDataButton disabled={units.length === 0} extraAction={closeAccordion} /></div>
          <div style={{ margin: 10 }}>{units.length === 0 && <div data-testid={'disabled-fetch'} className={styles.fetchDisabledText}>No common units for selected products</div>}</div>
        </>
      }
    </div>

  </div>
)