import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'

import * as serviceWorker from './shared/serviceWorker'
import './shared/i18n/i18n'

import { datadogRum } from '@datadog/browser-rum'

import { EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import msalInstance from './auth/msalInstance'

import { ThemeProvider } from './shared/themes/ThemeContext'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
)




const isTesting = process.env.JEST_WORKER_ID != undefined

const rootContainer = document.getElementById('root')
const root = ReactDOM.createRoot(rootContainer)


if (isTesting) { // Jest/Cypress is running, don't use auth because the test runner does not have a Suncor ID
  root.render(
    <ThemeProvider>
      <App />
    </ThemeProvider>
  )
}
else {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    env: process.env.REACT_APP_DD_ENVIRONMENT,
    site: 'datadoghq.com',
    service: 'trading-seasonality',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0', // TODO we should use version numbers...
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })

  datadogRum.startSessionReplayRecording()

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    console.log('found accounts, using [0]: ', msalInstance.getAllAccounts())
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }
  })


  root.render(
    <MsalProvider instance={msalInstance}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </MsalProvider>
  )
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
