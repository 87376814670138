import React from 'react'
import styles from './App.module.scss'
import AppRoutes from './AppRoutes'

import { Provider } from 'react-redux'
import store from './store/root'
import { useMsal, useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator'


const isTesting = process.env.JEST_WORKER_ID != undefined


const withAuthentication = () => {
  const { accounts } = useMsal()
  const name = accounts[0] && accounts[0].name


  const { login, result, error } = useMsalAuthentication('redirect')

  return <>
    <AuthenticatedTemplate>
      <div className={styles.appContainer}>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </div>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <LoadingIndicator reason={'Trying to sign you in...'} ready={!!name} />
    </UnauthenticatedTemplate>
  </>
}


const noAuth = () =>
  <div className={styles.appContainer}>
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  </div>


export default isTesting ? noAuth : withAuthentication
