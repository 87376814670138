import React from 'react'
import { connect } from 'react-redux'
import ProductRow from './ProductRow'
import Tooltip from '../../Tooltips/Tooltip'
import { useTranslation } from 'react-i18next'
import styles from './ProductParameters.module.scss'


export default connect(state => ({
  selectedStructure: state.seasonality.selectedStructure,
}))(({
  selectedStructure
}) => {

  const { t } = useTranslation()

  return selectedStructure && <div className={styles.root}>
    <table className={styles.table}>

      <thead>
        <tr>
          <th className={styles.headerCell}>Product</th>
          <th className={styles.headerCell}>Exchange Month</th>
          <th className={styles.headerCell}>
            <div className={styles.cellContainer}>
              <p>Leg Year</p>
              <Tooltip content={t('Tooltips.legYear')} />
            </div>
          </th>
          <th className={styles.headerCell}>Leg Weight</th>
          <th className={styles.headerCell}>Spread Leg</th>
          <th className={styles.headerCell}>Last Updated</th>
        </tr>
      </thead>

      <tbody>
        {
          selectedStructure?.defaultValues?.product.map((_, index) =>
            <ProductRow
              key={'row' + index}
              rowIndex={index}
              component={'td'}
            />
          )
        }
      </tbody>

    </table>
  </div>
})