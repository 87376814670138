import styles from './AnnualTable.module.scss'

export default unit => ({

  'detailedLabel': <div>
    This label indicates the products involved in the trade recommendation.<br /><br />
    <div>Each leg is encoded as: <br /><br />
      <div>Weight(ProductCode.Month)Year</div>
    </div>

    Example: 3-K0/M0/K0/M0 <br /><br />
    This trade is a Box. <br /><br />
    3 is the calendar month for which the trade is made<br /><br />
    K refers to first leg month <br /><br />
    0 refers to first leg year <br /><br />
    This follows for all legs
  </div>,


  'Trade_Type': <div>
    Trade type refers to recommended trade to make. <br /><br />
    If the Expected Down Move for the month is higher than the Expected Up Move, it{"'"}s a bear type and if reversed, bull type<br /><br />

    <div className={styles.underlineBold}>Calculation</div>
    Bear Type = Expected Down Move {'>'} Expected Up Move  <br /><br />
    Bull Type = Expected Up Move {'>'} Expected Down Move
  </div>,


  'Mo_Up_Pct': <div>
    Probability of the expected move to be a Bull trade. <br /><br />
    Of the 5 years fit window used to recommend a trade, if the price moved in positive direction 4 times, then the Bull probability is 0.8.<br /><br />

    <div className={styles.underlineBold}>Calculation</div>
    Bull Probability = # of positive (Bull) price moves / Total # of years in the fit window<br /><br />

    <div className={styles.underlineBold}>Units:</div> %

  </div>,


  'trade_up_sort': <div>
    The expected price move for the month in the positive direction <br /><br />

    <div className={styles.underlineBold}>Calculation</div>
    Expected Up Move = Bull probability * Probability Weighted Move<br /><br />

    <div className={styles.underlineBold}>Units:</div> {unit}

  </div>,


  "Mo_Dn_Pct": <div>
    Probability of the expected move to be a Bear trade. <br /><br />
    Of the 5 years fit window used to recommend a trade, if the price moved in negative direction 4 times, then the Bear probability is 0.8. <br /><br />

    <div className={styles.underlineBold}>Calculation</div>
    Bear Probability = # of negative (Bear) price moves / Total # of years in the fit window<br /><br />

    <div className={styles.underlineBold}>Units:</div> %

  </div>,


  'trade_dn_sort': <div>
    The expected price move for the month in the negative direction <br /><br />

    <div className={styles.underlineBold}>Calculation</div>
    Expected Down Move = Bear probability * Probability Weighted Move<br /><br />

    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'Mo_Wtd_Move': <div>
    Probability Weighted Move<br /><br />

    Weighted probability of both bear and bull move.<br /><br />


    <div className={styles.underlineBold}>Calculation</div>
    Probability Weighted Move = (Bull probability * Mean Move Up Amount) + (Bear Probability * Mean Move Down Amount) <br /><br />

    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'abs_trade_value': <div>
    Absolute Expected Move<br /><br />
    Column used to sort the trades by default with highest value trade on the top. It is the absolute value of the expected move of the recommended trade type.<br /><br />
    If a Bear trade is recommended, this column will have absolute value of the Expected down move<br /><br />

    <div className={styles.underlineBold}>Calculation</div>
    Absolute Expected Move = abs(Expected Up/Down Move(based on trade type )) <br /><br />

    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'pl_year_DaysToExpiry': <div>
    Days to expiry<br /><br />
    The number of days from the start of the selected trading month until the first leg of the structure expires. <br /><br />
    <div className={styles.underlineBold}>Units:</div> Days
  </div>,


  'median': <div>
    median<br /><br />
    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'mu': <div>
    mu<br /><br />
    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'sigma': <div>
    sigma<br /><br />
    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'min': <div>
    min<br /><br />
    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'max': <div>
    max<br /><br />
    <div className={styles.underlineBold}>Units:</div> {unit}
  </div>,


  'abs_trade_value_USDBBL': <div>
    Absolute Expected Move converted to USD/BBL<br /><br />

    <div className={styles.underlineBold}>Units:</div> {'USD/BBL'}

    <br /><br />
    <div className={styles.underlineBold}>NOTE:</div>
    This is useful when "Any" is selected because of varying unit of measures for some products. This is a standard unit for all rows so they can be compared, apples to apples.
  </div>

})

