import { loadingReasons } from '../constants'
import { addLoadingReason, removeLoadingReason } from './application'
import { setParamsFromURL } from './seasonality'
import { annualRecommendationRequest, parseQueryString, constructObjectFromQueryString } from './utils'
import { batch } from 'react-redux'
import _ from 'lodash'
import analyticsApi from '../analyticsApi'

const localStorageKey = 'annualRecommendationTableConfig'
// Constants
const SET_MODEL_MONTH = 'SET_MODEL_MONTH'
const SET_MODEL_STRUCTURE = 'SET_MODEL_STRUCTURE'
const SET_MODEL_LEG = 'SET_MODEL_LEG'
const SET_DEFAULT_ANNUAL_PARAMETERS = 'SET_DEFAULT_ANNUAL_PARAMETERS'

const SET_ANNUAL_RESPONSE_DATA = 'SET_ANNUAL_RESPONSE_DATA'
const SELECT_MODEL_RESPONSE_ROW = 'SELECT_MODEL_RESPONSE_ROW'
const TOGGLE_FRONT_MONTH_EXCLUSION = 'TOGGLE_FRONT_MONTH_EXCLUSION'
const SET_ABORT_CONTROLLERS = 'SET_ABORT_CONTROLLERS' // to abort previous request when user changes inputs quickly
// const SET_ANNUAL_RESPONSE_DATA_FROM_CACHE = 'SET_ANNUAL_RESPONSE_DATA_FROM_CACHE'

const COPY_SEASONALITY_FOR_ANNUAL_RECOMMENDATION = 'COPY_SEASONALITY_FOR_ANNUAL_RECOMMENDATION'
const SORT_ANNUAL_RESPONSE_DATA = 'SORT_ANNUAL_RESPONSE_DATA'
const SET_ANNUAL_TABLE_CONFIG = 'SET_ANNUAL_TABLE_CONFIG'

const SET_PROFIT_LOSS_GRANULARITY = 'SET_PROFIT_LOSS_GRANULARITY'


// Reducer
const getInitialState = () => ({
  annualMonth: null,
  annualStructure: null,
  annualLegs: [],
  // cache: {},
  response: [],
  selectedModelResponseRow: null,
  frontMonthExclusion: true,

  profitLoss: [],
  profitLossGranularity: 'Yearly',

  seasonality: null,
  annualRecommendationTableConfig: null,
  abortControllers: {}
})

export default (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case SET_MODEL_MONTH: {
      return { ...state, annualMonth: payload }
    }
    case SET_MODEL_STRUCTURE: {
      return { ...state, annualStructure: payload }
    }
    case SET_MODEL_LEG: {
      return { ...state, annualLegs: payload }
    }
    case SET_DEFAULT_ANNUAL_PARAMETERS: {
      return { ...state, ...payload }
    }
    case SET_ANNUAL_RESPONSE_DATA: {
      // return { ...state, cache: { ...state.cache, [payload.requestString]: [...payload.response] }, response: [...payload.response] }
      return { ...state, response: payload.recommendations, profitLoss: payload.profitLoss }
    }
    case SORT_ANNUAL_RESPONSE_DATA: {
      // return { ...state, cache: { ...state.cache, [payload.requestString]: [...payload.response] }, response: [...payload.response] }
      return { ...state, response: payload }
    }
    // case SET_ANNUAL_RESPONSE_DATA_FROM_CACHE: {
    //   return { ...state, response: [...payload] }
    // }
    case SELECT_MODEL_RESPONSE_ROW: {
      return { ...state, selectedModelResponseRow: payload }
    }
    case SET_ANNUAL_TABLE_CONFIG: {
      return { ...state, annualRecommendationTableConfig: payload }
    }
    case COPY_SEASONALITY_FOR_ANNUAL_RECOMMENDATION: {
      return { ...state, seasonality: payload }
    }
    case TOGGLE_FRONT_MONTH_EXCLUSION: {
      return { ...state, frontMonthExclusion: !state.frontMonthExclusion }
    }
    case SET_PROFIT_LOSS_GRANULARITY: {
      return { ...state, profitLossGranularity: payload }
    }
    case SET_ABORT_CONTROLLERS: {
      return { ...state, abortControllers: payload }
    }
  }
  return state
}




// Actions
export const setProfitLossGranularity = granularity => ({ type: SET_PROFIT_LOSS_GRANULARITY, payload: granularity })
export const toggleFrontMonthExclusion = () => ({ type: TOGGLE_FRONT_MONTH_EXCLUSION })


export const selectModelResponseRow = row => async (dispatch, getState) => {
  const { annualRecommendation: { response: modelResponse } } = getState()

  if (modelResponse.length < 1)
    return

  batch(() => {
    dispatch(addLoadingReason(loadingReasons.selectModelResponseRow, 'Fetching seasonality data for selected trade...'))
    dispatch(addLoadingReason(loadingReasons.calculatePriceMove, 'Calculating price move by year for selected trade...'))
    dispatch({ type: SELECT_MODEL_RESPONSE_ROW, payload: row })
  })

  const { web_parameters, DisplayUnit } = row

  const queryStringToParse = web_parameters.includes('unit=')
    ? web_parameters
    : `${web_parameters}&unit=${DisplayUnit}`

  await dispatch(
    setParamsFromURL(
      constructObjectFromQueryString(
        parseQueryString(queryStringToParse)
      )
    )
  )


  const { spreadStats, monthlyProfile, chartData, selectedUnit, chartTitle, queryString } = JSON.parse(JSON.stringify(getState().seasonality))
  const payload = { spreadStats, monthlyProfile, chartData, selectedUnit, chartTitle, queryString }

  batch(() => {
    dispatch({ type: COPY_SEASONALITY_FOR_ANNUAL_RECOMMENDATION, payload })
    dispatch(removeLoadingReason(loadingReasons.selectModelResponseRow))
    dispatch(removeLoadingReason(loadingReasons.calculatePriceMove))
  })
}


export const setModelMonth = val => (dispatch, getState) => {
  if (getState().annualRecommendation.annualMonth != val) {
    dispatch({ type: SET_MODEL_MONTH, payload: val })
    dispatch(fetchAnnualModelResults())
  }
}


export const setModelStructure = structure => (dispatch, getState) => {
  const { application: { calendarMonths, validAnnualProductCombinationMap }, annualRecommendation: { annualMonth } } = getState()

  const currentMonth = new Date().getMonth()
  const payload = {}

  payload.annualMonth = annualMonth ?? Object.values(calendarMonths)[currentMonth].value
  payload.annualStructure = structure

  switch (structure) {
    case 'Fly': {
      payload.annualLegs = Array.from({ length: 3 }, () => validAnnualProductCombinationMap[structure].defaultProducts)
      break
    }
    case 'Time Spread':
    case 'Crack':
    case 'Inter-Product Spread': {
      payload.annualLegs = [...validAnnualProductCombinationMap[structure].defaultProducts]
      break
    }
    case 'Box': {
      const tuple = validAnnualProductCombinationMap[structure].defaultProducts
      payload.annualLegs = [tuple[0], tuple[0], tuple[1], tuple[1]]
      break
    }
  }


  dispatch({ type: SET_DEFAULT_ANNUAL_PARAMETERS, payload })

  dispatch(fetchAnnualModelResults())
}


const oppositeIndexMap = {
  0: 2,
  1: 3,
  2: 0,
  3: 1
}
const neighbourIndexMap = {
  0: 1,
  1: 0,
  2: 3,
  3: 2
}
export const setModelLeg = (product, index) => (dispatch, getState) => {
  const { annualRecommendation: { annualStructure: currentStructure, annualLegs }, application: { annualStructures, validAnnualProductCombinationMap } } = getState()

  const { legs } = annualStructures[currentStructure]

  let payload = []

  /*
  RULES: 
    Fly: All legs the same product
    Time Spread: All legs the same product 
    Box: For each[val1, val2], leg 1 and 2 are val1 and leg 3 and 4 are val2
    Crack: For each[val1, val2], leg1 is val1 and leg2 is val2
    Inter - Product Spread: For each[val1, val2], leg1 is val1 and leg2 is val2
*/
  switch (currentStructure) {

    case 'Time Spread':
    case 'Fly': {
      payload = Array.from({ length: legs }, () => product)
      break
    }

    case 'Box': { // this is probably good as is 
      payload = [...annualLegs]
      payload[index] = product
      payload[neighbourIndexMap[index]] = product

      // if swapping legs 1,2 with 3,4 then we need to figure out what to set the opposite legs to since they cant all be the 
      if (annualLegs[oppositeIndexMap[index]] === product && product !== 'Any') {
        const previousProduct = annualLegs[index]
        // check if previous product is in the allowable selections for the opposite leg
        if (validAnnualProductCombinationMap['Box'][index][product].canCompareTo.includes(previousProduct)) {
          payload[oppositeIndexMap[index]] = previousProduct
          payload[oppositeIndexMap[neighbourIndexMap[index]]] = annualLegs[neighbourIndexMap[index]]
        }
        else {
          payload[oppositeIndexMap[index]] = validAnnualProductCombinationMap['Box'][index][product].canCompareTo[0]
          payload[oppositeIndexMap[neighbourIndexMap[index]]] = validAnnualProductCombinationMap.Box[index][product].canCompareTo[0]
        }
      }
      break
    }

    case 'Crack':
    case 'Inter-Product Spread': {
      payload = [...annualLegs]
      payload[index] = product
      // if (index === 0)
      //   payload[neighbourIndexMap[index]] = 'Any'
      if (index === 0) {
        if (product !== 'Any')
          payload[neighbourIndexMap[index]] = validAnnualProductCombinationMap[currentStructure][index][product].canCompareTo[0]

        else
          payload[neighbourIndexMap[index]] = validAnnualProductCombinationMap[currentStructure].validLastLegOptions[0]

      }
      break
    }

  }

  dispatch({ type: SET_MODEL_LEG, payload })
  try {

    dispatch(fetchAnnualModelResults())
  } catch (error) {
    console.log('set leg error: ', error)
  }
}


export const sortResponse = sortkey => (dispatch, getState) => {
  const { annualRecommendation: { response } } = getState()
  const sorted = JSON.parse(JSON.stringify(response)) // deep copy to avoid the dreaded state mutation warning
    .sort((a, b) => b[sortkey] - a[sortkey])
  if (!_.isEqual(response, sorted))
    dispatch({ type: SORT_ANNUAL_RESPONSE_DATA, payload: sorted })
}


export const reverseResponse = () => (dispatch, getState) => {
  const { annualRecommendation: { response } } = getState()
  const sorted = JSON.parse(JSON.stringify(response)) // deep copy to avoid the dreaded state mutation warning
    .reverse()
  dispatch({ type: SORT_ANNUAL_RESPONSE_DATA, payload: sorted })
}


export const fetchAnnualModelResults = () => async (dispatch, getState) => {
  // const cache = getState().annualRecommendation.cache
  const isLoadingPreviousRequest = getState().application.loading[loadingReasons.fetchAnnualModelResults]
  const abortControllers = Object.values(getState().annualRecommendation.abortControllers)

  if (isLoadingPreviousRequest && abortControllers.length) {
    dispatch({ type: SET_ABORT_CONTROLLERS, payload: {} })
    // remove from state before we call abort
    // otherwise redux screams at us :( (invariant state mutation)
    abortControllers.forEach(controller => controller.abort())
  }
  else {
    dispatch(addLoadingReason(loadingReasons.fetchAnnualModelResults, 'Looking for recommended trades...'))
  }
  // const requestString = requestParams.toString()
  // if (cache[requestString]) { // don't fetch if we've already seen that request
  //   dispatch({ type: SET_ANNUAL_RESPONSE_DATA_FROM_CACHE, payload: cache[requestString] })
  //   return  // }

  const annualModelAbortController = new AbortController()
  const profitLossAbortController = new AbortController()

  dispatch({
    type: SET_ABORT_CONTROLLERS,
    payload: {
      annualModelAbortController,
      profitLossAbortController
    }
  })


  try {
    const requestParams = annualRecommendationRequest(getState())

    // const annualResponseData = {
    //   recommendations: null,
    //   profitLoss: null
    // }

    const recommendations = (await analyticsApi.post('annual_model', {
      ...requestParams,
      signal: annualModelAbortController.signal
    })).data
    //new
    // const recommendationsPromise = analyticsApi.post('annual_model', {
    //   ...requestParams,
    //   signal: annualModelAbortController.signal
    // }).then(response => {
    //   // console.log('recommendations? ', _.isEqual(recommendations, response.data))
    //   annualResponseData.recommendations = response.data
    // })



    const profitLoss = (await analyticsApi.post('profit_loss', {
      ...requestParams,
      signal: profitLossAbortController.signal
    })).data
    // console.log('profitLoss', profitLoss)
    //new
    // const profitLossPromise = analyticsApi.post('profit_loss', {
    //   ...requestParams,
    //   signal: profitLossAbortController.signal
    // }).then(response => {
    //   annualResponseData.profitLoss = response.data
    // })



    // TODO cleanup
    if (profitLossAbortController.signal.aborted || annualModelAbortController.signal.aborted) {
      console.log('request aborted')
      dispatch({ type: SET_ABORT_CONTROLLERS, payload: {} })
      return
    }

    // await Promise.all([recommendationsPromise, profitLossPromise])

    dispatch({
      type: SET_ANNUAL_RESPONSE_DATA,
      payload: {
        // requestString,
        recommendations,
        profitLoss
      }
      //new
      // payload: annualResponseData
    })

    dispatch({ type: SET_ABORT_CONTROLLERS, payload: {} })

  } catch (error) {
    // if (analyticsApi.isCancel(error)) {
    console.log('request cancelled', error.message)
    // }

  }

  dispatch(removeLoadingReason(loadingReasons.fetchAnnualModelResults))
}


export const loadAnnualRecommendationTableConfigIntoState = defaultConfig => {
  if (!localStorage.getItem(localStorageKey)) {
    localStorage.setItem(localStorageKey, JSON.stringify(defaultConfig))
  }
  let currentConfiguration = JSON.parse(localStorage.getItem(localStorageKey))

  const defaultKeys = Object.keys(defaultConfig)

  Object.keys(currentConfiguration).forEach(key => {
    if (!defaultKeys.includes(key))
      delete currentConfiguration[key]
  })
  const currentlySavedKeys = Object.keys(currentConfiguration)

  if (currentlySavedKeys.length > defaultKeys.length) {
    currentlySavedKeys.forEach(key => {
      if (!defaultKeys.includes(key)) {
        delete currentConfiguration[key]
      }
    })
    localStorage.setItem(localStorageKey, JSON.stringify(currentConfiguration))
    currentConfiguration = JSON.parse(localStorage.getItem(localStorageKey))
  }

  if (currentlySavedKeys.length < defaultKeys.length) {
    defaultKeys.forEach(key => {
      if (!currentlySavedKeys.includes(key))
        currentConfiguration[key] = defaultConfig[key]
    })
    localStorage.setItem(localStorageKey, JSON.stringify(currentConfiguration))
    currentConfiguration = JSON.parse(localStorage.getItem(localStorageKey))
  }


  return { type: SET_ANNUAL_TABLE_CONFIG, payload: currentConfiguration }
}

export const toggleAnnualRecommendationTableConfigOption = key => (dispatch, getState) => {
  const { annualRecommendation: { annualRecommendationTableConfig: _annualRecommendationTableConfig } } = getState()

  const annualRecommendationTableConfig = JSON.parse(JSON.stringify(_annualRecommendationTableConfig))
  annualRecommendationTableConfig[key].visible = !annualRecommendationTableConfig[key].visible

  // Don't allow them to turn off all of the columns because it looks bad 
  if (Object.values(annualRecommendationTableConfig).every(config => !config.visible))
    return

  localStorage.setItem(localStorageKey, JSON.stringify(annualRecommendationTableConfig))
  dispatch({ type: SET_ANNUAL_TABLE_CONFIG, payload: annualRecommendationTableConfig })
}