export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,//"31e69a1b-5741-40f0-afd2-a31c643ec2a6",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,//1aa51068-11a6-4bd2-8646-1fff31a30ffc`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored. localStorage is another option.
    storeAuthStateInCookie: true, // Set this to 'true' if you are having issues on IE11 or Edge
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}