import analyticsApi from '../analyticsApi'

// Constants
const ADD_LOADING_REASON = 'ADD_LOADING_REASON'
const REMOVE_LOADING_REASON = 'REMOVE_LOADING_REASON'

const FETCH_LAST_UPDATED = 'FETCH_LAST_UPDATED'
const FETCH_STRUCTURE_LIST_AND_DEFAULTS = 'FETCH_STRUCTURE_LIST_AND_DEFAULTS'
const FETCH_PRODUCT_LIST = 'FETCH_PRODUCT_LIST'
const FETCH_CALENDAR_MONTHS = 'FETCH_CALENDAR_MONTHS'

const LOAD_METADATA_INTO_STATE = 'LOAD_METADATA_INTO_STATE'
const TOGGLE_CHART_TITLE_EXPANSION = 'TOGGLE_CHART_TITLE_EXPANSION'


// Reducer

const getInitialState = () => ({
  loading: {},
  chartTitleIsLongForm: false,

  // seasonality data lists
  lastUpdated: null,
  calendarMonths: {},
  products: {},
  structures: {},

  // annual model data lists
  annualProductList: {},
  annualStructures: {},
  validAnnualProductCombinationMap: {}

})


export default (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case ADD_LOADING_REASON: {
      return { ...state, loading: { ...state.loading, [payload.key]: payload.reason } }
    }
    case REMOVE_LOADING_REASON: {
      const loading = { ...state.loading }
      if (loading[payload.key])
        delete loading[payload.key]
      return { ...state, loading }
    }
    case FETCH_LAST_UPDATED: {
      return { ...state, lastUpdated: payload }
    }
    case FETCH_CALENDAR_MONTHS: {
      return { ...state, calendarMonths: payload }
    }
    case FETCH_STRUCTURE_LIST_AND_DEFAULTS: {
      return { ...state, structures: payload }
    }
    case FETCH_PRODUCT_LIST: {
      return { ...state, products: payload }
    }
    case LOAD_METADATA_INTO_STATE: {
      return { ...state, ...payload }
    }
    case TOGGLE_CHART_TITLE_EXPANSION: {
      return { ...state, chartTitleIsLongForm: !state.chartTitleIsLongForm }
    }
  }
  return state
}


// Actions
export const addLoadingReason = (key, reason) => ({ type: ADD_LOADING_REASON, payload: { key, reason } })
export const removeLoadingReason = key => ({ type: REMOVE_LOADING_REASON, payload: { key } })
export const toggleChartTitleLongForm = () => ({ type: TOGGLE_CHART_TITLE_EXPANSION })


export const getLastUpdated = () => async dispatch => {
  const { data: { Timestamp: lastUpdated } } = await analyticsApi.get('/get_last_log_entry')
  dispatch({ type: FETCH_LAST_UPDATED, payload: lastUpdated })
}


export const fetchProductList = () => async dispatch => {
  const { data } = await analyticsApi.get('/product_list')

  const products = {}
  data.forEach(({ value, minContract, maxContract, marketViewCode, Units, key, ConvertableUnits, alias }) => {
    products[value] = {
      minContract,
      maxContract,
      marketViewCode,
      units: Units,
      key,
      value,
      convertableUnits: ConvertableUnits,
      alias
    }
  })
  dispatch({ type: FETCH_PRODUCT_LIST, payload: products })
}

export const fetchCalendarMonths = () => async dispatch => {
  const { data } = await analyticsApi.get('/expiry_months')
  const calendarMonths = {}
  data.forEach(({ value, key, code }) => {
    calendarMonths[value] = { key, code, value }
  })
  dispatch({ type: FETCH_CALENDAR_MONTHS, payload: calendarMonths })
}


export const fetchStructureListAndDefaults = () => async (dispatch, getState) => {
  const { application: { products } } = getState()

  const { data } = await analyticsApi.get('/structure_list_and_defaults')
  const structures = {}

  data.forEach(({ LegNum, LegYear, Month, ProductId, outputFormula, value, Weight, key }) => {
    if (!structures[value])
      structures[value] = { value, key }

    if (structures[value].formula != outputFormula)
      structures[value].formula = outputFormula

    if (!structures[value].defaultValues)
      structures[value].defaultValues = {
        product: [],
        month: [],
        legYear: [],
        legWeight: [],
        units: undefined
      }

    // -1 because LegNum is indexed from 1 instead of 0
    structures[value].defaultValues.product[LegNum - 1] = +ProductId
    structures[value].defaultValues.month[LegNum - 1] = +Month
    structures[value].defaultValues.legYear[LegNum - 1] = +LegYear
    structures[value].defaultValues.legWeight[LegNum - 1] = +Weight

    if (!structures[value].defaultValues.units)
      structures[value].defaultValues.units = Object.values(products).find(product => product.key == ProductId).units
  })

  dispatch({ type: FETCH_STRUCTURE_LIST_AND_DEFAULTS, payload: structures })
}


export const loadMetadataIntoState = data => {
  const { data_start_year, Product_Structure_list } = data

  const originalData = JSON.parse(JSON.stringify(Product_Structure_list))

  // add "Any" combinations, and store a list of unique products for generating valid combinations
  const values = Object.values(originalData).map(structureConfiguration => {
    if (typeof structureConfiguration.Products[0] === 'string') { // Products is directly from default.json, combinations would be a better name.
      // structureConfiguration.Products.push('Any')
      structureConfiguration.uniqueProducts = [...structureConfiguration.Products]
    }
    else {
      const _uniqueProducts = new Set() // set skips duplicates
      structureConfiguration.Products.forEach(subarray => subarray.forEach(value => _uniqueProducts.add(value)))
      structureConfiguration.uniqueProducts = [..._uniqueProducts.values()]
    }
    return structureConfiguration
  })



  const productStructureListWithAnys = {}
  const annualProductCombinations = {}

  // This will create an object with the shape: 
  //
  // {
  //   structure: {
  //     legIndex: {
  //       product: { // for each valid product
  //          canCompareTo: [list]
  //       }
  //     },
  //     defaultProducts: [list],
  //     validFirstLegOptions: [list],
  //     validLastLegOptions: [list],
  //   }
  // }

  Object.keys(originalData).forEach((structure, originalDataIndex) => {

    productStructureListWithAnys[structure] = values[originalDataIndex]

    annualProductCombinations[structure] = {
      defaultProducts: values[originalDataIndex].Products[0]
    }
    if (structure === 'Fly') {
      annualProductCombinations[structure].validFirstLegOptions = [...values[originalDataIndex].Products, 'Any']
      annualProductCombinations[structure].validLastLegOptions = [...values[originalDataIndex].Products, 'Any']
    }
    else {
      annualProductCombinations[structure].validFirstLegOptions = [...(new Set(values[originalDataIndex].Products.map(tuple => tuple[0]))).values(), 'Any']
      annualProductCombinations[structure].validLastLegOptions = [...(new Set(values[originalDataIndex].Products.map(tuple => tuple[1]))).values(), 'Any']
    }


    // TODO can we remove combination index to reduce the depth of this object?
    const legIndexes = Array.from({ length: values[originalDataIndex].legs }, (_, i) => i)
    legIndexes.forEach((_, legIndex) => {
      annualProductCombinations[structure][legIndex] = {}

      values[originalDataIndex].uniqueProducts.forEach(product => {

        annualProductCombinations[structure][legIndex][product] = {
          canCompareTo: []
        }

        if (['Fly', 'Time Spread'].includes(structure)) {
          annualProductCombinations[structure][legIndex][product] = {
            canCompareTo: [product]
          }
        }
        else if (['Crack', 'Inter-Product Spread'].includes(structure)) {
          const missingIndexMap = {
            0: 1,
            1: 0
          }
          const legIndexMap = {
            0: 0,
            1: 1
          }
          originalData[structure].Products.forEach(value => {
            if (value[legIndexMap[legIndex]] === product) {
              annualProductCombinations[structure][legIndex][product].canCompareTo.push(value[missingIndexMap[legIndex]])
            }
          })
        }
        else {
          const missingIndexMap = {
            0: 1,
            1: 1,
            2: 0,
            3: 0
          }
          const legIndexMap = { // map box leg index to tuple leg index
            0: 0,
            1: 0,
            2: 1,
            3: 1
          }
          originalData[structure].Products.forEach(tuple => {
            if (tuple[legIndexMap[legIndex]] === product) {
              annualProductCombinations[structure][legIndex][product].canCompareTo.push(tuple[missingIndexMap[legIndex]])
            }
          })
        }
        if (annualProductCombinations[structure][legIndex][product].canCompareTo.length)
          annualProductCombinations[structure][legIndex][product].canCompareTo.push('Any')
        else // only comparing to any without any other valid things, is invalid
          delete annualProductCombinations[structure][legIndex][product] // if "any" is the only comparable product, it has no comparable products. 
      })

    })

  })

  Object.freeze(annualProductCombinations)

  const payload = {
    annualProductList: data_start_year,
    annualStructures: productStructureListWithAnys,
    validAnnualProductCombinationMap: annualProductCombinations
  }

  return { type: LOAD_METADATA_INTO_STATE, payload }
}
