import times from 'lodash/times'
import styles from './NotificationView.module.scss'
import NotificationView from './NotificationView'



// interface Message {
//   text: string
//   type: string
//   duration: number
//   node: Element
//   listener: (e: Event) => void
// }

class Notifier {
  // messages: Message[]
  // timer: any
  // maxMessages: number
  // container: Element

  constructor() {
    this.timer = undefined
    this.messages = []
    this.maxMessages = 5

    this.container = document.createElement('div')
    this.container.className = styles.container
    document.body.appendChild(this.container)
  }

  show(text, { type = '', duration = 2000 }) {
    let node = document.createElement('div')
    node.className = styles.appearEffect
    node.insertAdjacentHTML('beforeend', NotificationView(text, type))

    const eventListener = e => {
      if (node && this.messages.includes(message)) {
        const messageIndex = this.messages.indexOf(message)
        this.removeLog(messageIndex)
      }
    }

    const message = {
      text: text,
      duration: duration,
      type: type,
      node: node,
      listener: eventListener,
    }

    node.addEventListener('click', eventListener)

    this.container.appendChild(node)
    this.messages.push(message)

    if (this.messages.length > this.maxMessages) {
      times(this.messages.length - this.maxMessages, () => this.removeLog())
    }

    if (this.messages.length === 1) {
      this.updateTimer(message.duration)
    }
  }

  updateTimer(expirationTime) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.removeLog(), expirationTime)
  }

  removeLog(index) {
    if (this.messages.length > 0) {
      if (index) {
        this.removeNode(
          this.messages[index].node,
          this.messages[index].listener,
        )
        this.messages.splice(index, 1)
      } else {
        this.removeNode(this.messages[0].node, this.messages[0].listener)
        this.messages.shift()
      }

      if (this.messages.length > 0) {
        this.updateTimer(this.messages[0].duration)
      }
    }
  }

  // we need some time to play disappearing animation before actually removing element from DOM
  removeNode(node, listener) {
    node.classList.add(styles.disappearEffect)
    node.removeEventListener('click', listener)
    setTimeout(() => node.remove(), 300)
  }
}

export default new Notifier()
