import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const ExpandableList = ({ header, content, openByDefault = false }) => {
  const [open, setOpen] = useState(openByDefault)

  const handleClick = () => {
    setOpen(!open)
  }

  return <div style={{ width: '100%', maxWidth: '100%', paddingTop: 10 }}>
    <ListItemButton onClick={handleClick} sx={{ backgroundColor: '#ddd', '&:hover': { textDecoration: 'underline', backgroundColor: '#ccc' } }}>
      <ListItemText disableTypography primary={header} style={{ fontFamily: 'NotoSans' }} />
      {open
        ?
        <ExpandLess />
        :
        <ExpandMore />
      }
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit sx={{ backgroundColor: '#eee', padding: 2, display: 'flex', textAlign: 'centre' }}>
      <List component="div">
        {content}
      </List>
    </Collapse>
  </div>
}

export default ExpandableList

export const ConnectedExpandableList = ({ section, sectionKey, header }) => {
  const objectMap = useSelector(state => state[section][sectionKey])
  return <ExpandableList
    header={header}
    content={
      Object.keys(objectMap).map((product, index) =>
        <div key={'unique key for ' + product + index}>
          {product}
        </div>
      )
    }
  />
}
