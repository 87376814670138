import React from 'react'
import styles from './SeasonalityPage.module.scss'
import TimeParameters from '../../components/Tables/TimeParameters/TimeParameters'
import ProductParameters from '../../components/Tables/ProductTable/ProductParameters'
import StructureParameters from '../../components/Tables/StructureParameters/StructureParameters'
import { ConnectedLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import DownloadMenu from '../../components/DownloadMenu/DownloadMenu'
import MonthlyProfile from '../../components/Tables/MonthlyProfile'
import SpreadStats from '../../components/Tables/SpreadStats'
import SeasonalityGraph from '../../components/ScatteredGraph/SeasonalityGraph'
import AccordionPage from '../AccordionPage'
import { loadingReasons } from '../../constants'
import testIds from '../../testIds'


const MakeSummaryContentWithOpenAndClose = (open, close) => <>
  <div className={styles.detailsContent}>
    <TimeParameters />
    {/* Not sure about this loading indicator, I think it looks weird. - Cody */}
    {/* <ConnectedLoadingIndicator loadingKeys={[loadingReasons.setParamsFromURL]}> */}
    <StructureParameters openAccordion={open} closeAccordion={close} />
    {/* </ConnectedLoadingIndicator> */}
  </div>
  <ConnectedLoadingIndicator loadingKeys={[loadingReasons.setParamsFromURL]} testid={testIds.Loading[7]}>
    <ProductParameters />
  </ConnectedLoadingIndicator>
</>

const PageContentWithOpen = open => <>
  <div className={styles.dataContainer}>
    <ConnectedLoadingIndicator loadingKeys={[loadingReasons.setParamsFromURL, loadingReasons.fetchSeasonalityData]} testid={testIds.Loading[8]}>
      <DownloadMenu openAccordion={open} />
      <SpreadStats />
    </ConnectedLoadingIndicator>
    <ConnectedLoadingIndicator loadingKeys={[loadingReasons.setParamsFromURL, loadingReasons.fetchSeasonalityData]} testid={testIds.Loading[9]}>
      <MonthlyProfile />
    </ConnectedLoadingIndicator>
  </div>
  <div className={styles.graphContainer}>
    <ConnectedLoadingIndicator loadingKeys={[loadingReasons.setParamsFromURL, loadingReasons.fetchSeasonalityData]} testid={testIds.Loading[10]}>
      <SeasonalityGraph />
    </ConnectedLoadingIndicator>
  </div>
</>

export default ({ testId = '' }) => <div id="capture-for-download">
  <AccordionPage
    detailsContent={MakeSummaryContentWithOpenAndClose}
    pageContent={PageContentWithOpen}
    testId={testId || testIds.ApplicationPages[0]}
  />
</div>