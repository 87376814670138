import React, { useState, useEffect } from 'react'
import styles from './Textbox.module.scss'


export default ({ handleChange, value, testidprefix }) => {

  const [changingValue, setChangingValue] = useState(+value)
  const [inputValidated, setInputValidated] = useState(true)

  useEffect(() => { setChangingValue(+value) }, [value]) // Needed in case you change structure. Value would come in as new default value to be displayed

  useEffect(() => {
    if (!isNaN(+changingValue)) {
      setInputValidated(true)
      if (+changingValue != +value) handleChange(+changingValue)
    }
    else setInputValidated(false)
  }, [changingValue])


  return <div className={styles.container}>
    <input type="text" value={changingValue}
      data-testid={testidprefix}
      className={styles.textInput}
      onChange={e => setChangingValue(e.target.value)}
      onBlur={() => setChangingValue(+changingValue)} // this turns "0000" into 0
    />
    {!inputValidated &&
      <p className={styles.helperText}>Enter a valid numeric number</p>
    }
  </div>
}