import React from 'react'
import styles from './AnnualRecommendation.module.scss'
import AccordionPage from '../AccordionPage'
import ModelParameters from '../../components/ModelParameters/ModelParameters'
import AnnualTable from '../../components/Tables/AnnualTable/AnnualTable'
import { ConnectedLoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import { SpreadStats_Annual } from '../../components/Tables/SpreadStats'
import { MonthlyProfile_Annual } from '../../components/Tables/MonthlyProfile'
import { SeasonalityGraph_Annual } from '../../components/ScatteredGraph/SeasonalityGraph'
import AnnualHistoricalPriceMove from '../../components/HorizontalBarGraph/AnnualHistoricalPriceMove'
import ProfitLoss from '../../components/VerticalBarGraph/ProfitLoss'
import { loadingReasons } from '../../constants'
import testIds from '../../testIds'



const MakeSummaryContentWithOpenAndClose = (open, close) => <>
  <ModelParameters openAccordion={open} closeAccordion={close} />
  <div className={styles.tableContainer}>
    <ConnectedLoadingIndicator loadingKeys={[loadingReasons.fetchAnnualModelResults]} testid={testIds.Loading[1]}>
      <AnnualTable />
    </ConnectedLoadingIndicator>
  </div>
</>

const PageContent = () => <>
  <div className={styles.doubleGraphContainer}>
    <div className={styles.priceMoveContainer}>
      <ConnectedLoadingIndicator loadingKeys={[loadingReasons.calculatePriceMove, loadingReasons.fetchAnnualModelResults, loadingReasons.selectModelResponseRow]} testid={testIds.Loading[2]}>
        <AnnualHistoricalPriceMove />
      </ConnectedLoadingIndicator>
    </div>
    <div className={styles.profitLossContainer}>
      <ConnectedLoadingIndicator loadingKeys={[loadingReasons.fetchAnnualModelResults]} testid={testIds.Loading[3]}>
        <ProfitLoss />
      </ConnectedLoadingIndicator>
    </div>
  </div>
  <div className={styles.dataContainer}>
    <ConnectedLoadingIndicator loadingKeys={[loadingReasons.fetchAnnualModelResults, loadingReasons.selectModelResponseRow, loadingReasons.setParamsFromURL]} testid={testIds.Loading[4]}>
      <SpreadStats_Annual />
    </ConnectedLoadingIndicator>
    <ConnectedLoadingIndicator loadingKeys={[loadingReasons.fetchAnnualModelResults, loadingReasons.selectModelResponseRow, loadingReasons.setParamsFromURL]} testid={testIds.Loading[5]}>
      <MonthlyProfile_Annual />
    </ConnectedLoadingIndicator>
  </div>
  <div className={styles.graphContainer}>
    <ConnectedLoadingIndicator loadingKeys={[loadingReasons.fetchAnnualModelResults, loadingReasons.selectModelResponseRow, loadingReasons.setParamsFromURL]} testid={testIds.Loading[6]}>
      <SeasonalityGraph_Annual />
    </ConnectedLoadingIndicator>
  </div>
</>


export default () =>
  <AccordionPage
    detailsContent={MakeSummaryContentWithOpenAndClose}
    pageContent={PageContent}
    testId={testIds.ApplicationPages[2]}
  />
