import styles from './ErrorView.module.scss';
import sanitizeHTML from '../sanitizeHTML';

export default message => `
  <div class='${styles.wrapper}'>
    <div class='${styles.body}'>
      <div class='${styles.title}'>${
  message ? sanitizeHTML(message) : 'Something went wrong!'
}</div>
      <div class='${styles.subtitle}'>
        please contact Peter Wares [
        <span class='${styles.email}'>pwares@suncor.com</span>
        ]
      </div>
    </div>
  </div>
`;
