import styles from './Help.module.scss'
import { ConnectedExpandableList } from '../../ExpandableList/ExpandableList'
import AnnualRecommendationCombinationsAccordion from '../../ExpandableList/AnnualRecommendationCombinationsAccordion'

const generalSeasonality = 'Exploratory analysis of various structures and how they perform at different times of the year.'
const generalAnnualRecommendations = 'Trades recommended based on month, product and structure selections. Ranked based on price moves (5 years). The model assumes that the trader will enter on the first day of the month and exit on the last day of the month.'
const generalDailyOutliers = 'The daily outlier algorithm is being used for identifying potential trades which are currently priced away from their usual trends. Outliers are being detected as a short term outlier and a longer term outlier (based on historical prices)'


const generalHelp = [
  {
    header: 'What is this?',
    subtext: <p style={{ paddingTop: 10 }}>
      Your one-stop shop for exploring the seasonality of commodity trades, as well as some trades recommended on a monthly basis. More features to come!
    </p>
  },
  {
    header: 'Seasonality',
    subtext: <p style={{ paddingTop: 10 }}>
      {generalSeasonality}
    </p>
  },
  {
    header: 'Annual Recommendations',
    subtext: <p style={{ paddingTop: 10 }}>
      {generalAnnualRecommendations}
    </p>
  },
  {
    header: 'Daily Outliers',
    subtext: <p style={{ paddingTop: 10 }}>
      {generalDailyOutliers}
    </p>
  },
  {
    header: 'Technical Info',
    subtext: <>
      <h4 style={{ textDecoration: 'underline', paddingTop: 10 }}>Update Frequency:</h4>
      <p>Data is updated on a daily basis. The last time the data was updated is displayed in the header bar at the top of the page.</p>
      <h4 style={{ textDecoration: 'underline', paddingTop: 10 }}>Licenses:</h4>
      <p>Currently, all data comes from marketview. This application has its own license. A license for MarketView can be requested from Suncor's STO group.</p>
    </>
  }
]



const seasonalityHelp = [
  {
    header: 'About',
    subtext: <div style={{ paddingTop: 10 }}>
      {generalSeasonality}
    </div>
  },
  {
    header: 'Usage',
    subtext: <>
      <div style={{ paddingTop: 10 }}>- Select a starting year, and optionally, a year to exclude</div>
      <div style={{ paddingTop: 10 }}>- Select a structure, and units you want the data to be</div>
      <div style={{ paddingTop: 10 }}>- Select products and set parameters for each product</div>
      <div style={{ paddingTop: 10 }}>- Click fetch to get the resulting data</div>
    </>
  },
  {
    header: 'Utility',
    subtext: <>
      <div style={{ paddingTop: 10 }}>- Right click seasonality graphs' title and select "Copy Link" to get a link which can be saved for later and/or shared with another trader or analyst</div>
      <div style={{ paddingTop: 10 }}>- Click download to get the seasonality data in a spreadsheet, and a PDF representation of the current screen</div>
    </>
  },
  {
    header: 'Structures & Products',
    subtext: <>
      <ConnectedExpandableList
        section={'application'}
        sectionKey={'structures'}
        header={'Currently Available Structures'}
      />
      <ConnectedExpandableList
        section={'application'}
        sectionKey={'products'}
        header={'Currently Available Products'}
      />
    </>
  },
]

const annualRecommendationHelp = [
  {
    header: 'About',
    subtext: <div style={{ paddingTop: 10 }}>
      {generalAnnualRecommendations}
    </div>
  },
  {
    header: 'Usage',
    subtext: <>
      <div style={{ paddingTop: 10 }}>- Select a trading month to get recommendations for</div>
      <div style={{ paddingTop: 10 }}>- Select a structure</div>
      <div style={{ paddingTop: 10 }}>- Select products, starting from Leg 1 (See the following "Products" section for more details)</div>
      <div style={{ paddingTop: 10 }}>- Data is automatically fetched, the page will always recommend based on the selections</div>
      <div style={{ paddingTop: 10 }}>- Select a row in the Recommended Trades table. Historical price move will reflect that selected trade.</div>
    </>
  },
  {
    header: 'Structures & Products',
    subtext: <>
      <div style={{ paddingTop: 10 }}>At this time, only a subset of what is available in Seasonality is available within Annual Recommendations.</div>
      <div style={{ paddingTop: 10 }}>For an optimal experience, it is recommended to set legs in ascending order.</div>
      <ConnectedExpandableList
        header={'Currently Available Structures'}
        section={'application'}
        sectionKey={'annualStructures'}
      />
      <ConnectedExpandableList
        header={'Currently Available Products'}
        section={'application'}
        sectionKey={'annualProductList'}
      />
    </>
  },
  {
    header: 'Currently Available Combinations by Structure',
    subtext: <AnnualRecommendationCombinationsAccordion />
  }
]


const dailyOutlierHelp = [
  {
    header: 'About',
    subtext: <div style={{ paddingTop: 10 }}>
      {generalDailyOutliers}
    </div>
  },
  {
    header: 'Methodology',
    subtext: <div>
      <h3>Short-Term Outliers</h3>
      <span>Three Z-Scores for each trade are being calculated:</span>
      <ul>
        <li>Current Z-Score – Z-Score of the recent price with the average of the last one year of prices (Approximately 250 trading days)</li>
        <li>14 Day Z-Score – Z-Score of the recent price with the average of the last two weeks of prices (Approximately last 10 trading days)</li>
        <li>30 Day Z-Score – Z-Score of the recent price with the average of the last month of prices (Approximately last 21 trading days)</li>
      </ul>
      <span>Outlier is being detected if either of the following conditions are true: </span>
      <ul>
        <li>Absolute value of Current Z-Score is greater than or equal to 2</li>
        <li>Absolute value of the difference between Current Z-Score and 14 Day Z-Score is greater than or equal to 1</li>
        <li>Absolute value of the difference between Current Z-Score and 30 Day Z-Score is greater than or equal to 1.5</li>
      </ul>
      <br />
      <h3>Long-Term Outliers</h3>
      <p>Z-Score for each trade is being calculated of the recent price with the average price of same trading month in last 5 years (5 Yr History ZS).</p>
      <p>Outlier is detected if the absolute value of 5 Yr History ZS is greater than or equal to 2.</p>
    </div>
  },
  {
    header: 'About Emails',
    subtext: <p>
      Emails are sent out on a daily basis, before market open. You will receive the email if you have signed up. Within the email, you can expect to find a table for each structure in your preferences. That table will show outliers which include products from your preferences.
    </p>
  }
]


const definitionsAndCalculations = [
  {
    header: 'Overview',
    subtext: <>
      The Annual model is concerned with detecting seasonality in trades/combination of trades in a monthly time frame.<br />
      The model is built to analyze how different commodities/structure prices have moved in different months in history and calculate an expected move in upcoming months, based on which a trade recommendation could be made.
    </>
  },
  {
    header: 'Monthly Move Calculation',
    subtext: <>
      The model calculates monthly moves as the difference between closing price of a trade on the
      1st day of the month and last day of the month.<br />
      <br />
      Example:<br />
      <div style={{ paddingLeft: 10 }}>
        Trade: WTI Futures - Fly - March(H) / June(M) / October(V)<br />
        Trading Month: January<br />
        Monthly Move in January: (CP of WTI - Fly - H / M / V on January 1st) - (CP of WTI - Fly - H / M / V on January 31st)
      </div>
    </>
  },
  {
    header: 'Future Months',
    subtext: <>
      Considering the trading month as the month in which a trade can be placed, there are a limited
      number of future contracts that are considered. The limit is 13 months.<br />
      <br />
      Example:<br />
      <div style={{ paddingLeft: 10 }}>
        For a trading month of April 2022, any structure we select, the furthest leg (mostly the last leg)
        should not be more than 13 months away (April 2023).<br />
        We would consider combinations of future contracts from May 2022 to April 2023.
      </div>
    </>
  },
  {
    header: 'Training Window',
    subtext: <>
      Training window is the number of historical years that are taken into account for calculating
      expected move. This window is generally 5 years, but could be changed if required.<br />
      <br />
      Example: <br />
      <div style={{ paddingLeft: 10 }}>
        For recommending trades for 2022, data from 2017 to 2021 (inclusive) would be considered for training.
      </div>
    </>
  },
  {
    header: 'Expected Move',
    subtext: <>
      Expected Move is calculated as Expected Up Move and Expected Down Move.<br />
      Expected Up Move is the move that shows how much the price is expected to go up for a Product/Structure combination from the start to the end of a trading month.<br />
      Expected Down Move is the move that shows how much the price is expected to go down for a Product/Structure combination from the start to the end of a trading month.<br />
      These moves would help recommend a trade as a Bear or Bull Trade (going long or short on a trade).<br />
      It is calculated in two steps. First a weighted move for the month is calculated.<br />
    </>
  },
  {
    header: 'Monthly Weighted Move',
    subtext: <>
      <div style={{ textDecoration: 'underline', paddingTop: 8 }}>Price Delta:</div> Closing price on Last Day of the month - Closing Price on First Day of the month<br />
      <div style={{ textDecoration: 'underline', paddingTop: 8 }}>Move up percent:</div> fraction of times price delta was positive for a particular trading month in the past 5 years(Or no.of years in training window)<br />
      <div style={{ textDecoration: 'underline', paddingTop: 8 }}>Move down percent:</div> fraction of times price delta was negative for a particular trading month in the past 5 years(Or no.of years in training window)<br />
      <div style={{ textDecoration: 'underline', paddingTop: 8 }}>Up Move Avg:</div> Average of magnitudes of up moves in the training window<br />
      <div style={{ textDecoration: 'underline', paddingTop: 8 }}>Down Move Avg:</div> Average of magnitudes of down moves in the training window<br />
      <br />
      Monthly Wtd Move = (Up Move Avg * Move Up Percent) + (Down Move Avg * Move Down Percent)<br />
      Expected Up Move = Monthly Wtd Move * Move Up Percent<br />
      Expected Down Move = Monthly Wtd Move * Move Down Percent<br />
    </>
  },
  {
    header: 'Trade Recommendation',
    subtext: <>
      A trade is recommended as a Bear or Bull trade based on the Expected Move.<br />
      <div style={{ textDecoration: 'underline', paddingTop: 10 }}>Absolute Trade Value:</div>
      max(abs(Expected Up Move), abs(Expected Down Move))<br />
      <div style={{ textDecoration: 'underline', paddingTop: 10 }}>Trade Recommendation Type:</div>
      Bear if abs(Expected Down Move) &#62; abs(Expected Up Move)<br />
      Bull if abs(Expected Up Move) &#60; abs(Expected Down Move)<br />
      <br />
      Trade recommendations are ranked from Highest to Lowest Absolute Trade Value <br />
    </>
  }
]



const HelpList = ({ list, _key }) =>
  <div className={styles.listContainer} key={list.length + list[list.length - 1].header}>
    {
      list.map(({ header, subtext }, index) =>
        <div key={_key + index + index} style={{ paddingTop: 0 }}>
          {
            header &&
            <div className={styles.title} key={index + header + _key}>
              {header}
            </div>
          }
          {
            subtext &&
            <div className={styles.contentSubtext} key={index + header + index + index}>
              {subtext}
            </div>
          }
        </div>
      )
    }
  </div>


const helpPages = [
  {
    title: 'General',
    content: <HelpList _key={'help1'} list={generalHelp} />
  },
  {
    title: 'Seasonality',
    content: <HelpList _key={'help2'} list={seasonalityHelp} />
  },
  {
    title: 'Annual Recommendations',
    content: <HelpList _key={'help3'} list={annualRecommendationHelp} />
  },
  {
    title: 'Daily Outliers',
    content: <HelpList _key={'help4'} list={dailyOutlierHelp} />
  },
  {
    title: 'Definitions/Calculations',
    content: <HelpList _key={'help5'} list={definitionsAndCalculations} />
  }
]

export default helpPages