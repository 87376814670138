import { Radio, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Tooltip from '../../../components/Tooltips/Tooltip'
import * as DailyOutlierActions from '../../../store/dailyOutliers'
import styles from '../DailyOutliers.module.scss'

const recent = 'Short-Term Outliers'
const historical = 'Long-Term Outliers'
const both = 'Both'

export const labelValueMap = {
  'short': recent,
  'long': historical,
  'both': both,
  [recent]: 'short',
  [historical]: 'long',
  [both]: 'both'
}

const textMap = {
  'short': {
    'summary': <>
      <div className={styles.termToggleText}>Prefer outliers based on current, 14-day, and 30-day Z-scores</div>
    </>,
    'tooltip': <div>
      <h3>Short-Term Outliers</h3>
      <span>A Short-Term Outlier is detected if any of the following criteria is met:</span>
      <ul>
        <li>Absolute value of the Current Z-Score is greater than or equal to 2</li><br />
        <li>Absolute value of the difference between Current Z-Score and 14 Day Z-Score is greater than or equal to 1</li><br />
        <li>Absolute value of the difference between Current Z-Score and 30 Day Z-Score is greater than or equal to 1.5</li>
      </ul>
    </div>
  },
  'long': {
    'summary': <>
      <div className={styles.termToggleText}>Prefer outliers based on the average price of the current month (past 5 years)</div>
    </>,
    'tooltip': <div>
      <h3>Long-Term Outliers</h3>
      <p>A Long-Term Outlier is detected if the absolute value of the 5 year History Z-Score is greater than or equal to 2.</p>
      <p>The 5 year history Z-Score is calculated of the recent price with the average price of same trading month in last 5 years (5 Yr History ZS).</p>
    </div>
  },
  'both': {
    'summary': <>
      <div className={styles.termToggleText}>Equally consider short and long term outliers</div>
    </>
  }
}


export const TimePreferenceCard = ({ label, currentValue, onClick, testid, animated = false }) =>
  <div className={animated ? styles.termToggleAnimated : styles.termToggle} onClick={onClick} data-testid={testid}>
    {
      textMap[labelValueMap[label]].tooltip &&
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <Tooltip content={<>{textMap[labelValueMap[label]].tooltip}</>} />
      </div>
    }

    <h3 style={{ textAlign: 'center' }}>{label}</h3>

    {textMap[labelValueMap[label]].summary}

    <div className={styles.termToggleSwitch}>
      {
        animated && <>
          {label}
          <Radio
            checked={currentValue === labelValueMap[label]}
            value={labelValueMap[label]}
            name='radio-buttons'
            inputProps={{ 'aria-label': labelValueMap[label] }}
            data-testid={testid + '-radio'}
          />
        </>
      }
    </div>
  </div>



export const TimePreferenceSummary = ({ label, currentValue }) =>
  <>
    <hr style={{ marginTop: '5%', width: '60%' }} />

    <div className={styles.termToggleContainer}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto' }}>
        <h3>Short-Term/Long-Term Preference:</h3>
      </div>
      <TimePreferenceCard
        label={label}
        currentValue={currentValue}
      />
    </div>

    <hr style={{ marginTop: '4%', marginBottom: '2%', width: '60%' }} />
  </>



export default connect(state => ({
  term: state.dailyOutliers.term
}), {
  updateTerm: DailyOutlierActions.updateDailyOutlierTerm
})(
  ({ term, updateTerm, nextPage, previousPage, testid }) => {

    const [currentValue, setCurrentValue] = useState(term)

    useEffect(() => {
      if (currentValue != term) setCurrentValue(term)
    }, [term])



    return <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', paddingBottom: '10%' }} data-testid={testid}>
      <h1>Daily Outlier Timeframe Preference</h1>
      <h2>Select one of the available options</h2>

      <div className={styles.termToggleContainer}>
        <TimePreferenceCard
          animated
          label={recent}
          currentValue={currentValue}
          onClick={() => updateTerm('short')}
          testid={testid + '-short'}
        />
        <TimePreferenceCard
          animated
          label={historical}
          currentValue={currentValue}
          onClick={() => updateTerm('long')}
          testid={testid + '-long'}
        />
      </div>

      <div className={styles.termToggleContainer}>
        <TimePreferenceCard
          animated
          label={both}
          currentValue={currentValue}
          onClick={() => updateTerm('both')}
        />
      </div>


      <div className={styles.doubleButtonContainer}>
        <Button
          variant={'contained'}
          color={'inherit'}
          children={'Cancel'}
          onClick={previousPage}
          data-testid={testid + '-back'}
        />
        <Button
          variant={'contained'}
          children={'Accept'}
          sx={{ marginLeft: 15 }}
          onClick={nextPage}
          data-testid={testid + '-next'}
        />
      </div>

    </div >
  })