import React, { Fragment } from 'react'
import ExpandableList from './ExpandableList'
import metadata from '../../default.json'
import { Checkbox } from '@mui/material'
import styles from './ExpandableList.module.scss'

const { Product_Structure_list: combinationMap } = metadata

export default () => {
  return Object.keys(combinationMap).map(structure => {
    const combinations = combinationMap[structure].Products
    let content
    switch (structure) {
      case 'Fly': {
        content = combinations.map(singleProduct =>
          <div key={singleProduct + 'unique key3'}>
            &#62; {`${singleProduct} / ${singleProduct} / ${singleProduct}`}
          </div>
        )
        break
      }
      case 'Time Spread':
      case 'Crack':
      case 'Inter-Product Spread': {
        content = combinations.map(([leg1, leg2]) =>
          <div key={leg1 + leg2 + 'unique key4'}>
            &#62; {`${leg1} / ${leg2}`}
          </div>
        )
        break
      }
      case 'Box': {
        content = combinations.map(([leg12, leg34]) =>
          <div key={leg12 + leg34 + 'unique key 24'}>
            &#62; {`${leg12} / ${leg12} / ${leg34} / ${leg34}`}
          </div>
        )
        break
      }
    }
    return <Fragment key={'structure unique key: ' + structure}>
      <ExpandableList header={structure} content={content} />
    </Fragment>
  })

}



export const SelectableCombinations = ({ structureMap, updateSelections = null, testid = 'error!no-test-id' }) => {
  // for each structure
  return Object.keys(structureMap).map(structure => {

    const combinations = structureMap[structure].combinations

    const toggleCombination = !!updateSelections
      ? productCombination => {
        updateSelections[combinations[productCombination] ? 'remove' : 'add'](structure, productCombination)
      }
      : null

    // for each combination
    const content = Object.keys(combinations).map((productCombination, index) =>
      (structureMap[structure].combinations[productCombination] || !!updateSelections)
      &&
      <div key={productCombination + structure}
        className={updateSelections ? styles.selectableRow : styles.selectedRow}
        style={{ cursor: !!updateSelections ? 'pointer' : 'default' }}
        onClick={
          !!updateSelections
            ?
            () => { toggleCombination(productCombination) }
            :
            null
        }
        data-testid={testid + '-' + structure + '-' + index}
      >
        <Checkbox checked={structureMap[structure].combinations[productCombination]} disabled={!updateSelections} />
        {/* SEPARATOR */}
        {productCombination.replace(/&&&/g, ' / ')}
      </div>
    )

    return structureMap[structure].enabled && <Fragment key={'different unique: ' + structure}>
      <ExpandableList header={structure} content={content} openByDefault />
    </Fragment>
  })

}