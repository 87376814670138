import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Checkbox } from '@mui/material'
import { connect } from 'react-redux'
import { SelectableCombinations } from '../../../components/ExpandableList/AnnualRecommendationCombinationsAccordion'
import * as DailyOutlierActions from '../../../store/dailyOutliers'
import styles from '../DailyOutliers.module.scss'

export default connect(state => ({
  preferences: state.dailyOutliers.preferences
}), {
  disableDailyOutlierStructure: DailyOutlierActions.disableDailyOutlierStructure,
  enableDailyOutlierStructure: DailyOutlierActions.enableDailyOutlierStructure,
  addDailyOutlierCombination: DailyOutlierActions.addDailyOutlierCombination,
  removeDailyOutlierCombination: DailyOutlierActions.removeDailyOutlierCombination,
  getUserDailyOutlierPreferences: DailyOutlierActions.getUserDailyOutlierPreferences
})(
  ({
    nextPage,
    previousPage = null,
    preferences,
    disableDailyOutlierStructure,
    enableDailyOutlierStructure,
    addDailyOutlierCombination,
    removeDailyOutlierCombination,
    getUserDailyOutlierPreferences,
    testid
  }) =>
    <div className={styles.centeredColumn} data-testid={testid}>
      <h1>Daily Email Preferences</h1>
      <h2 className={styles.flexInstructionText} />
      {/* this h2 text is controlled by css */}

      <div className={styles.selectionsContainer}>

        <div className={styles.structureSelections}>
          {
            Object.keys(preferences).map(structure =>
              <div
                key={'structure keys' + structure}
                className={styles.structureSelectionRow}
                onClick={() => {
                  preferences[structure].enabled
                    ?
                    disableDailyOutlierStructure(structure)
                    :
                    enableDailyOutlierStructure(structure)
                }}
                data-testid={testid + '-' + structure}
              >
                <Checkbox
                  checked={preferences[structure].enabled}
                />
                {structure}
              </div>
            )
          }
        </div>

        <div className={styles.combinationSelections}>
          <SelectableCombinations
            structureMap={preferences}
            updateSelections={{ add: addDailyOutlierCombination, remove: removeDailyOutlierCombination }}
            testid={testid}
          />
        </div>

      </div>

      <div className={styles.doubleButtonContainer}>
        <Button
          color={'inherit'}
          variant={'contained'}
          children={<><ArrowBackIcon />Back</>}
          onClick={async () => {
            await getUserDailyOutlierPreferences()
            previousPage()
          }}
          data-testid={testid + '-back'}
        />
        <Button
          variant={'contained'}
          children={'Continue'}
          sx={{ marginLeft: 15 }}
          // at least 1 structure enabled
          // for each enabled structure, at least 1 combo is enabled
          disabled={
            !(
              Object.values(preferences)
                .map(structure =>
                  (structure.enabled && Object.values(structure.combinations).some(combination => combination === true))
                  || (!structure.enabled && Object.values(structure.combinations).every(combination => combination === false))
                )
                .every(val => val === true)
              && Object.values(preferences).map(structure => structure.enabled).some(enabled => enabled === true)
            )
          }
          onClick={nextPage}
          data-testid={testid + '-next'}
        />
      </div>

    </div>
)
