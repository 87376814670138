import { Button } from '@mui/material'
import styles from '../DailyOutliers.module.scss'

export default ({ nextPage, testid }) =>
  <div className={styles.centeredColumn} data-testid={testid}>
    <h1>Daily Outlier Email Sign Up</h1>

    <div className={styles.preamble}>
      <h2>
        Follow the steps below to register yourself as a recipient of the Daily Outlier Email.
        For each structure you have selected, you will receive a shortlist of top outliers based on selected product combinations.
      </h2>
    </div>

    <ol style={{ marginTop: 40, textAlign: 'left' }}>
      <li>Select Structures of interest</li>
      <li>Select Product combinations for each selected structure</li>
      <li>Confirm and subscribe</li>
    </ol>

    <div className={styles.buttonContainer}>
      <h4>You will receive a Daily email.</h4>
      <Button
        variant={'contained'}
        children={'Acknowledge'}
        onClick={nextPage}
        data-testid={testid + '-next'}
      />
    </div>
  </div>