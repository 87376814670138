import React from 'react'
import { connect } from 'react-redux'
import styles from './Header.module.scss'
import NavigationSquares from '../NavigationSquares/NavigationSquares'
import Help from './Help/Help'
import Logo from '../SuncorLogo/Logo'
import testIds from '../../testIds'


export default connect(state => ({
  lastUpdated: state.application.lastUpdated
}))(({ lastUpdated }) =>
  <header className={styles.header} data-testid={testIds.Header[0]}>
    <div className={styles.left}>
      <NavigationSquares />
      <div style={{ marginLeft: 10 }}></div>
      <Logo type='default' />
    </div>
    <div className={styles.titleAndUpdated}>
      <div className={styles.title}>Trading Seasonality Tool</div>
      <div className={styles.lastUpdated} data-testid={testIds.Header[1]}>Last updated: {lastUpdated}</div>
    </div>
    <div className={styles.right}>
      <Help />
    </div>
  </header>
)