import React from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { getAnnualRecommendationUnit, getUniqueYears } from '../../store/selector'
import Tooltip from '../Tooltips/Tooltip'
import styles from './AnnualHistoricalPriceMove.module.scss'
import testIds from '../../testIds'


const ToolTipContent = <div>
  <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Historical Price Move</div>
  How the price of selected trade moved each year in the history<br />
  <br />
  Each Blue Bar is the price move in determined currency for the corresponding year
</div>


export default connect(state => ({
  modelResponse: state.annualRecommendation.response,
  selectedModelResponseRow: state.annualRecommendation.selectedModelResponseRow,
  yearLabels: getUniqueYears(state),
  annualRecommendationUnit: getAnnualRecommendationUnit(state)
}))(({
  modelResponse, selectedModelResponseRow, yearLabels, annualRecommendationUnit
}) => {

  if (!modelResponse?.length || !selectedModelResponseRow) return null

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: true,
    responsive: true,
    elements: {
      bar: {
        borderWidth: 0,
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          align: 'center',
          text: 'Price Move (' + annualRecommendationUnit + ')',
        }
      },
      y: {
        title: {
          display: true,
          align: 'center',
          text: 'Years',
        }
      }
    },
    plugins: {
      datalabels: {
        color: 'black',
        formatter: function (value, _context) {
          return ('' + value).substring(0, 7)
        },
        display: function ({ dataset: { data }, dataIndex }) {
          return data[dataIndex] >= -0.01 && 0.01 >= data[dataIndex]
        },
        align: 'start'
      },
      legend: {
        display: false
      },
      title: {
        display: false,
        text: 'Historical Price Move '
      }
    }
  }

  const xData = modelResponse
    .filter(row =>
      row.Product1 === selectedModelResponseRow.Product1
      && row.Product2 === selectedModelResponseRow.Product2
      && row.Product3 === selectedModelResponseRow.Product3
      && row.Product4 === selectedModelResponseRow.Product4
      && row.relative_label_interpreted == selectedModelResponseRow.relative_label_interpreted
    )
    .sort((a, b) => +a.CalYear - +b.CalYear)
    .map(row => row.Mo_Move)

  const chartData = {
    labels: yearLabels,
    backgroundColor: 'blue',
    datasets: [
      {
        label: annualRecommendationUnit,
        data: xData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return <>
    <div style={{ position: 'absolute', top: 10, right: '5%' }}>
      <Tooltip content={ToolTipContent} />
    </div>
    <div className={styles.titleText}>
      Historical Price Move - {selectedModelResponseRow.relative_label_interpreted}
    </div>
    <div data-testid={testIds.AnnualRecommendation[0]}>
      <Bar options={options} data={chartData} plugins={[ChartDataLabels]} data-testid={testIds.AnnualRecommendation[0]} />
    </div>
  </>

})