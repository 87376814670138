import React from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import * as SeasonalityActions from '../../store/seasonality'
import testIds from '../../testIds'


export default connect(state => ({
  selectedStructure: state.seasonality.selectedStructure
}), {
  clearParameters: SeasonalityActions.clearParameters
})(({
  selectedStructure, clearParameters
}) =>
  <Button
    variant="contained"
    color="error"
    disabled={!selectedStructure}
    onClick={clearParameters}
    data-testid={testIds.Seasonality[2]}
  >
    Clear
  </Button>
)