

export default ({ type = 'default' }) => {
  if (type !== 'default') console.warn('No other types yet.')
  return <svg xmlns="http://www.w3.org/2000/svg" width="95" height="40" fill="none" viewBox="0 0 95 40" role="image">
    <title>Suncor Energy Inc.</title>
    <path fill="#fff" d="M19.43 17.6l-.04 5.386c0 .82-.019 1.639-.019 2.458 0 1.17.504 1.757 1.804 1.757 1.204 0 1.747-.625 1.767-1.971l.077-7.63h4.851l-.077 7.18c-.019 4.196-1.494 6.713-6.87 6.713-4.91 0-6.443-2.986-6.443-5.483 0-.839 0-1.932.02-2.907l.077-5.503h4.852zm10.245 0h6.017l2.891 7.785h.039l.039-7.785h4.309l-.117 13.503h-5.997l-3.105-7.864h-.039l.136 7.864h-4.27l.098-13.503zm25.501 13.503c-.563.116-1.184.214-1.843.292-.64.06-1.36.098-2.155.098-4.697 0-7.2-3.24-7.2-7.141 0-4.332 2.503-7.142 7.763-7.142a16.206 16.206 0 013.55.351l-.64 4.04c-.652-.128-1.314-.2-1.979-.216-3.106 0-3.687 1.815-3.687 2.946 0 1.406.64 2.986 3.453 2.986.716 0 1.43-.058 2.137-.175l.601 3.961zm7.471-13.893c4.697 0 7.2 3.239 7.2 7.142 0 3.902-2.503 7.141-7.2 7.141s-7.2-3.24-7.2-7.141c0-3.903 2.503-7.143 7.2-7.143zm0 3.98c-1.69 0-2.193 1.425-2.193 3.162 0 1.736.504 3.16 2.193 3.16s2.193-1.424 2.193-3.16c0-1.737-.504-3.162-2.193-3.162zm21.231 9.913h-5.357l-2.846-5.74-.066 5.74h-4.754l.097-13.503h7.103c3.765 0 4.95 1.834 4.95 4.058 0 1.796-1.204 3.337-2.893 3.415v.04l3.766 5.99zm-8.17-7.552h.99c.872 0 1.571-.097 1.571-1.249 0-.916-.271-1.463-1.456-1.463h-1.087l-.018 2.713zM.568 27.336c1.19.268 2.407.398 3.626.388 1.872 0 2.736-.169 3.186-.622a1.133 1.133 0 00.33-.831c0-1.124-.734-1.378-1.374-1.473l-2.452-.606C.84 23.458 0 21.132 0 19.712a5.313 5.313 0 011.533-3.925c1.193-1.2 2.871-1.823 5.868-1.823 1.785.003 3.567.145 5.33.423l-.356 3.626a19.43 19.43 0 00-3.875-.355c-1.514 0-3.062.194-3.062 1.631 0 1.015 1.035 1.223 1.375 1.291l2.824.535c2.332.42 3.616 2.044 3.616 4.572 0 3.959-2.278 5.884-6.963 5.884-2.07 0-4.137-.165-6.18-.492l.458-3.743z" ></path>
    <path fill="url(#energy-gradient)" d="M92.82 23.2c0 3.408-.894 6.756-2.591 9.706A19.317 19.317 0 0183.15 40h2.775a22.077 22.077 0 006.61-7.73 22.206 22.206 0 00.243-19.835 22.084 22.084 0 00-6.418-7.89A21.933 21.933 0 0077.103.387a21.875 21.875 0 00-10.127.46 21.958 21.958 0 00-8.847 4.98 22.117 22.117 0 00-5.684 8.439h3.989a19.323 19.323 0 019.14-8.714 19.182 19.182 0 0112.54-1.18 19.269 19.269 0 0110.59 6.86A19.45 19.45 0 0192.818 23.2z"></path>
    <defs>
      <linearGradient id="energy-gradient" x1="64.969" x2="92.413" y1="5.402" y2="29.659" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#FFC425"></stop>
        <stop offset="0.15" stopColor="#FEBF26"></stop>
        <stop offset="0.33" stopColor="#FDB027"></stop>
        <stop offset="0.50" stopColor="#FA982A"></stop>
        <stop offset="0.75" stopColor="#F6772E"></stop>
        <stop offset="0.95" stopColor="#F04C34"></stop>
        <stop offset="1.00" stopColor="#EF4135"></stop>
      </linearGradient>
    </defs>
  </svg>
}