import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

import metadata from './default.json'
import testIds from './testIds'
import { defaultAnnualRecommendationTableConfig, loadingReasons, routes } from './constants'

import * as ApplicationActions from './store/application'
import * as AnnualRecommendationActions from './store/annualRecommendation'

import SeasonalityPage from './pages/Seasonality/SeasonalityPage'
import SeasonalityQueryParameters from './pages/Seasonality/SeasonalityQueryParameters'
import AnnualRecommendationPage from './pages/AnnualRecommendation/AnnualRecommendationPage'
import DailyOutliers from './pages/DailyOutliers/DailyOutliersStepper'

import Header from './components/Header/Header'
import { ConnectedLoadingIndicator } from './components/LoadingIndicator/LoadingIndicator'

export default connect(null, {
  addLoadingReason: ApplicationActions.addLoadingReason,
  removeLoadingReason: ApplicationActions.removeLoadingReason,
  getLastUpdated: ApplicationActions.getLastUpdated,
  fetchProductList: ApplicationActions.fetchProductList,
  fetchCalendarMonths: ApplicationActions.fetchCalendarMonths,
  fetchStructureListAndDefaults: ApplicationActions.fetchStructureListAndDefaults,
  loadMetadataIntoState: ApplicationActions.loadMetadataIntoState,
  loadAnnualRecommendationTableConfigIntoState: AnnualRecommendationActions.loadAnnualRecommendationTableConfigIntoState,
  setModelStructure: AnnualRecommendationActions.setModelStructure
})(({
  addLoadingReason, removeLoadingReason,
  getLastUpdated, fetchProductList, fetchCalendarMonths,
  fetchStructureListAndDefaults, setModelStructure,
  loadMetadataIntoState, loadAnnualRecommendationTableConfigIntoState
}) => {

  const [ready, setReady] = useState(false)

  useEffect(() => {
    (async () => {
      addLoadingReason(loadingReasons.init, 'Retrieving product, calendar, and structure data...')
      const promises = [
        getLastUpdated(),
        fetchProductList(),
        fetchCalendarMonths(),
      ]
      await Promise.all(promises)
      await fetchStructureListAndDefaults()
      loadMetadataIntoState(metadata)
      loadAnnualRecommendationTableConfigIntoState(defaultAnnualRecommendationTableConfig)
      setModelStructure('Box')
      setReady(true)
      removeLoadingReason(loadingReasons.init)
    })()
  }, [])

  return <ConnectedLoadingIndicator loadingKeys={[loadingReasons.init]} isReady={ready} testid={testIds.Loading[0]}>
    <Router>
      <Header />

      <Routes>
        <Route path={routes['Seasonality']} element={<SeasonalityPage />} />
        <Route path='powerbi' element={<SeasonalityQueryParameters />} />
        <Route path={routes['Annual Recommendation']} element={<AnnualRecommendationPage />} />
        <Route path={routes['Daily Email Preferences']} element={<DailyOutliers />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  </ConnectedLoadingIndicator>
})
