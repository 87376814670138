import React from 'react'
import { connect } from 'react-redux'
import styles from './Tables.module.scss'
import { monthlyProfileFields } from '../../constants'
import testIds from '../../testIds'


const MonthlyProfile = ({ monthlyProfile, seasonality = false }) => {

  if (!monthlyProfile) return null

  const testidprefix = (seasonality ? testIds.SeasonalityPrefixes[9] : testIds.AnnualRecommendationPrefixes[13])

  return <div className={styles.container} data-testid={testidprefix + testIds.Table[0]}>
    <table className={styles.table}>

      <thead>
        <tr>
          <th className={styles.headerCell} key={'header cal month'}>Cal Month</th>
          {
            Array.from({ length: 12 }, (_, i) => i).map(val =>
              <th className={styles.headerCell} key={'header ' + val}>
                {1 + val}
              </th>
            )
          }
        </tr>
      </thead>

      <tbody>
        {monthlyProfileFields.map((field, fieldsIndex) =>
          <tr key={field + '' + '_' + fieldsIndex}>
            <th className={styles.headerCell}>{field}</th>
            {
              monthlyProfile.map((entry, dataIndex) =>
                <td className={styles.bodyCell} key={dataIndex + '' + entry[field] + fieldsIndex} data-testid={testidprefix + testIds.Table[1] + '-' + field + '-' + (dataIndex + 1)}>
                  {entry[field]}
                </td >
              )
            }
          </tr>
        )}
      </tbody>

    </table>
  </div>
}

export default connect(state => ({
  monthlyProfile: state.seasonality.monthlyProfile,
  seasonality: true
}))(MonthlyProfile)


export const MonthlyProfile_Annual = connect(state => ({
  monthlyProfile: state.annualRecommendation.seasonality?.monthlyProfile
}))(MonthlyProfile)