import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Popover } from '@mui/material'
import Tooltip from '../../Tooltips/Tooltip'
import styles from './AnnualTable.module.scss'
import * as AnnualRecommendationActions from '../../../store/annualRecommendation'
import { getAnnualRecommendationUnit, getCurrentRecommendationsFromResponse, getAnnualLegsIncludesAnys, getAnnualStructureFormula } from '../../../store/selector'
import { default as SortIcon } from '../../Icons/SortingTriangle'
import SettingsIcon from '../../Icons/SettingsIcon'
import EyeIcon from '../../Icons/EyeIcon'
import { FrontMonthExclusionToggle } from '../../Toggle/Toggle'
import { makeChartTitleFromRecommendation } from '../../../store/utils'
import testIds from '../../../testIds'
import makeTooltipMap from './MakeTooltipMap'


const makePretty = val =>
  (typeof val == 'number')
    ? Math.round((val + Number.EPSILON) * 10000) / 10000
    : val


export default connect(state => ({
  selectedModelResponseRow: state.annualRecommendation.selectedModelResponseRow,
  modelResponse: getCurrentRecommendationsFromResponse(state),
  annualRecommendationTableConfig: state.annualRecommendation.annualRecommendationTableConfig,
  allProducts: state.application.products,
  annualRecommendationUnit: getAnnualRecommendationUnit(state),
  anyAnys: getAnnualLegsIncludesAnys(state),
  formula: getAnnualStructureFormula(state)
}), {
  selectModelResponseRow: AnnualRecommendationActions.selectModelResponseRow,
  sortResponse: AnnualRecommendationActions.sortResponse,
  reverseResponse: AnnualRecommendationActions.reverseResponse,
  toggleAnnualRecommendationTableConfigOption: AnnualRecommendationActions.toggleAnnualRecommendationTableConfigOption
})(({
  modelResponse, selectedModelResponseRow, annualRecommendationUnit,
  annualRecommendationTableConfig, anyAnys,
  selectModelResponseRow, sortResponse, reverseResponse,
  toggleAnnualRecommendationTableConfigOption,
  allProducts, formula
}) => {

  if (modelResponse.length <= 0) return null
  // TODO tests fail because modelResponse is empty <div data-testid={'codywashere'}>{modelResponse}</div>//<div>no data</div>

  const tooltipsMap = makeTooltipMap(annualRecommendationUnit)

  const [anchorEl, setAnchorEl] = useState(false)

  const handleClose = () => {
    setTimeout(() => setAnchorEl(null), 100)
  }

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  // sorting
  const [sortedBy, setSortedBy] = useState('abs_trade_value')
  const [reversed, setReversed] = useState(false)


  const sort = key => {
    if (sortedBy == key) {
      reverseResponse(key)
      setReversed(!reversed)
      return
    }

    setSortedBy(key)
    sortResponse(key)
    setReversed(false)
  }


  useEffect(() => {
    selectModelResponseRow(modelResponse[0])
  }, [modelResponse])


  useEffect(() => {
    if (anyAnys.hasAnys) sort('abs_trade_value_USDBBL')
    else if (sortedBy !== 'abs_trade_value') sort('abs_trade_value')
  }, [anyAnys.hasAnys])


  const onColumnClick = val => {
    if (
      (val.sortable && sortedBy !== 'abs_trade_value_USDBBL') ||
      (sortedBy === 'abs_trade_value_USDBBL' && !anyAnys.hasAnys)
    )
      sort(val.sortkey)

    if (!val.sortable) return

    else if (anyAnys.hasAnys)
      alert('Sorting is disabled with one or more "Any" product(s) selected. This is because of a potential variance in the units. To get sorting, select specific products. ')
  }



  return <>
    <div className={styles.units} data-testid={testIds.AnnualRecommendationTable[0]}>Recommended Trades ({annualRecommendationUnit})</div>

    <div className={styles.config} onClick={handleOpen} data-testid={testIds.AnnualRecommendationPrefixes[9] + testIds.Icon[0]}>
      <SettingsIcon width={15} height={15} />
      <div className={styles.tooltip}>Show/Hide columns</div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {
          annualRecommendationTableConfig &&
          Object.keys(tooltipsMap).map((key, mapIndex) => {
            const { visible, headerText, sortkey } = annualRecommendationTableConfig[key]
            return <div
              key={'some key' + mapIndex * 400}
              className={visible ? styles.configRow : styles.hiddenConfigRow}
              onClick={() => { toggleAnnualRecommendationTableConfigOption(sortkey) }}
              data-testid={testIds.AnnualRecommendationPrefixes[9] + testIds.Icon[1] + '-' + sortkey}
            >
              <EyeIcon visible={visible} width={16} height={16} />
              <div className={styles.paddingLeft}>{headerText}</div>
            </div>
          })
        }
      </Popover>
    </div>


    <div className={styles.container}>
      <table className={styles.table}>

        <thead>
          <tr>
            {
              Object.keys(tooltipsMap).map(key => {
                const val = annualRecommendationTableConfig[key]
                return val.visible &&
                  <th key={'header ' + val.headerText} className={styles.sticky} scope='col'>
                    <div className={val.sortable ? styles.sortableHeaderCell : styles.headerCell}
                      onClick={() => onColumnClick(val)}
                      data-testid={testIds.AnnualRecommendationPrefixes[10] + key}
                    >
                      {
                        (val.sortable && sortedBy == val.sortkey) &&
                        <SortIcon reversed={reversed} additionalStyle={{ marginLeft: 3 }} />
                      }
                      <Tooltip content={tooltipsMap[val.sortkey]}>
                        <div className={styles.headerText}>{val.headerText}</div>
                      </Tooltip>
                    </div>
                    {/* UNDERLINE FOR COLUMN HEADERS */}
                    <hr style={{ marginLeft: -10, marginRight: -10, marginTop: 0, marginBottom: 0 }} />
                  </th>
              })
            }
          </tr>
        </thead>

        <tbody>
          {
            modelResponse.slice(0, 25).map((row, rowIndex) =>
              <tr key={rowIndex + 'row annual'} style={{ cursor: 'pointer' }} data-testid={testIds.AnnualRecommendationTable[1]}>
                {
                  Object.keys(tooltipsMap).map(field => // follow the order of the tooltips map. alternatively, we could force an order from within loadAnnualRecommendationTableConfigIntoState
                    annualRecommendationTableConfig[field].visible &&
                    <td
                      key={rowIndex + field}
                      onClick={() => selectModelResponseRow(row)}
                      className={
                        // are all key/values equal?
                        Object.keys(selectedModelResponseRow || {}).every(key => row[key] === selectedModelResponseRow[key])
                          ?
                          styles.selectedBodyCell
                          :
                          styles.bodyCell
                      }
                    >
                      <div
                        className={styles.cellContainer}
                        data-testid={testIds.AnnualRecommendationPrefixes[11] + field}
                      >
                        {
                          field === 'detailedLabel'
                            ?
                            makeChartTitleFromRecommendation(row, formula, allProducts)
                            :
                            makePretty(row[field])
                        }
                      </div>
                    </td>
                  )
                }
              </tr>
            )
          }
        </tbody>

      </table>

    </div>
    <div className={styles.toggleContainer}>
      <FrontMonthExclusionToggle />
    </div>

  </>
})