import { useState } from 'react'
import { connect } from 'react-redux'
import styles from './NavigationSquares.module.scss'
import { Popover } from '@mui/material'
import Link from '../Link/Link'
import * as SeasonalityActions from '../../store/seasonality'
import { NavigationSquares } from '../../testIds'


// This is stolen from Jira's top left corner hehe......
// <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} role="presentation">
//   <path fill={color} fill-rule="evenodd" d="M4 5.01C4 4.451 4.443 4 5.01 4h1.98C7.549 4 8 4.443 8 5.01v1.98C8 7.549 7.557 8 6.99 8H5.01C4.451 8 4 7.557 4 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 13.549 7.557 14 6.99 14H5.01C4.451 14 4 13.557 4 12.99v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C14 7.549 13.557 8 12.99 8h-1.98C10.451 8 10 7.557 10 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C20 7.549 19.557 8 18.99 8h-1.98C16.451 8 16 7.557 16 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm-12 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 19.549 7.557 20 6.99 20H5.01C4.451 20 4 19.557 4 18.99v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98z" >
//   </path>
// </svg>


// This is taken from Jira and then fed through SVGR playground
// export default ({ width, height, color, ...rest }) =>
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox={`0 0 ${100} ${100}`}
//     style={{
//       enableBackground: `new 0 0 ${100} ${100}`,
//     }}
//     xmlSpace="preserve"
//   // {...props}
//   >
//     <path
//       style={{
//         fill: "#020202",
//       }}
//       d="M116.066 270.268c0 7.751-6.284 14.035-14.035 14.035H14.035C6.284 284.303 0 278.019 0 270.268v-87.996c0-7.751 6.284-14.035 14.035-14.035h87.996c7.752 0 14.035 6.284 14.035 14.035v87.996zM452.54 270.268c0 7.751-6.284 14.035-14.035 14.035h-87.997c-7.751 0-14.035-6.284-14.035-14.035v-87.997c0-7.751 6.284-14.035 14.035-14.035h87.997c7.751 0 14.035 6.284 14.035 14.035v87.997zM270.272 2.305h-88.004c-7.749 0-14.032 6.283-14.032 14.04v87.989c0 7.755 6.283 14.038 14.032 14.038h88.004c7.749 0 14.032-6.283 14.032-14.038V16.345c0-7.757-6.283-14.04-14.032-14.04zM102.028 2.305H14.04C6.283 2.305 0 8.588 0 16.345v87.989c0 7.755 6.283 14.038 14.04 14.038h87.989c7.755 0 14.038-6.283 14.038-14.038V16.345c-.001-7.757-6.284-14.04-14.039-14.04zM438.5 2.305h-87.988c-7.755 0-14.038 6.283-14.038 14.04v87.989c0 7.755 6.283 14.038 14.038 14.038H438.5c7.757 0 14.04-6.283 14.04-14.038V16.345c0-7.757-6.283-14.04-14.04-14.04zM270.272 168.236h-88.004c-7.749 0-14.032 6.283-14.032 14.04v87.989c0 7.755 6.283 14.038 14.032 14.038h88.004c7.749 0 14.032-6.283 14.032-14.038v-87.989c0-7.757-6.283-14.04-14.032-14.04zM270.272 334.167h-88.004c-7.749 0-14.032 6.283-14.032 14.04v87.989c0 7.755 6.283 14.038 14.032 14.038h88.004c7.749 0 14.032-6.283 14.032-14.038v-87.989c0-7.757-6.283-14.04-14.032-14.04zM116.066 436.2c0 7.751-6.284 14.035-14.035 14.035H14.035C6.284 450.235 0 443.951 0 436.2v-87.996c0-7.751 6.284-14.035 14.035-14.035h87.996c7.752 0 14.035 6.284 14.035 14.035V436.2zM452.54 436.2c0 7.751-6.284 14.035-14.035 14.035h-87.997c-7.751 0-14.035-6.284-14.035-14.035v-87.997c0-7.751 6.284-14.035 14.035-14.035h87.997c7.751 0 14.035 6.284 14.035 14.035V436.2z"
//     />
//   </svg >



export default connect(null, {
  resetSeasonality: SeasonalityActions.clearParameters
})(({ resetSeasonality }) => {

  const [anchorEl, setAnchorEl] = useState(false)

  const handleClose = () => {
    setTimeout(() => setAnchorEl(null), 100)
    // resetSeasonality()
  }


  return <>
    {/* 9 square thingy */}
    <div className={styles.container} onClick={event => setAnchorEl(event.currentTarget)} data-testid={NavigationSquares[0]}>
      {Array.from({ length: 3 }).map((_, i) =>
        <div className={styles.row} key={i}>
          {Array.from({ length: 3 }).map((__, ii) =>
            <div className={styles.item} id='item' key={i + '' + ii} />
          )}
        </div>
      )}
    </div>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <div className={styles.navigationPane} data-testid={NavigationSquares[1]}>
        <div className={styles.title}>Exploratory Analysis</div>
        <div className={styles.gradientUnderline} />
        <Link destination={'Seasonality'} beforeNavigating={resetSeasonality} testid={NavigationSquares[2]} closePopover={handleClose} />

        <div className={styles.title}>Prescriptive Analysis</div>
        <div className={styles.gradientUnderline} />
        <Link destination={'Annual Recommendation'} testid={NavigationSquares[3]} closePopover={handleClose} />

        <div className={styles.title}>Outliers</div>
        <div className={styles.gradientUnderline} />
        <Link destination={'Daily Email Preferences'} testid={NavigationSquares[4]} closePopover={handleClose} />
      </div>
    </Popover>
  </>
})
