import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as AnnualRecommendationActions from '../../store/annualRecommendation'
import { getCalendarMonths, getModelStructureList } from '../../store/selector'
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown'
import styles from './ModelParameters.module.scss'
import { AnnualRecommendationPrefixes } from '../../testIds'


const getOptions = (map, legs, legIndex, structure) => {

  if (legIndex === 0 || (legIndex === 1 && structure === 'Box') || structure === 'Fly' || structure === 'Time Spread') // fly, time spread are all the same product for all legs
    return [...map.validFirstLegOptions]


  if (legs[0] === 'Any' || (legs[1] === 'Any' && structure === 'Box')) { // box is a 4 leg structure represented by array of 2 such that: [0, 1] => [0,0, 1,1]
    return map.validLastLegOptions
  }

  const potentialOptions = [...map['0'][legs[0]].canCompareTo]

  const fullList = new Set(potentialOptions)

  return _.without([...fullList.values()], legs[legIndex]) // remove currently selected
}


export default connect(state => ({
  annualMonth: state.annualRecommendation.annualMonth,
  annualStructure: state.annualRecommendation.annualStructure,
  annualLegs: state.annualRecommendation.annualLegs,
  structureList: getModelStructureList(state),
  calendarMonths: getCalendarMonths(state),
  validCombinationMap: state.application.validAnnualProductCombinationMap
}),
  {
    setModelMonth: AnnualRecommendationActions.setModelMonth,
    setModelStructure: AnnualRecommendationActions.setModelStructure,
    setModelLeg: AnnualRecommendationActions.setModelLeg
  })
  (({
    annualMonth, annualStructure, annualLegs, calendarMonths, structureList,
    setModelMonth, setModelStructure, setModelLeg, validCombinationMap,
    _open, _close
  }) => {

    return <div className={styles.container}>
      <div className={styles.container}>
        <div className={styles.tuple}>
          Trading Month
          <SearchableDropdown
            testidprefix={AnnualRecommendationPrefixes[0]}
            list={calendarMonths}
            handleChange={setModelMonth}
            selectedValue={annualMonth}
          />
        </div>
        <div className={styles.tuple}>
          Structure
          <SearchableDropdown
            testidprefix={AnnualRecommendationPrefixes[1]}
            selectedValue={annualStructure}
            list={structureList}
            handleChange={setModelStructure}
          />
        </div>
      </div>
      <div className={styles.container}>
        {
          annualLegs && annualLegs.map((leg, legIndex) =>
            <div className={styles.tuple} key={'annualLegs' + legIndex}>
              Leg {legIndex + 1}
              <SearchableDropdown
                testidprefix={AnnualRecommendationPrefixes[2 + legIndex]}
                selectedValue={leg}
                list={
                  getOptions(
                    validCombinationMap[annualStructure],
                    annualLegs,
                    legIndex,
                    annualStructure
                  )
                }
                handleChange={val => setModelLeg(val, legIndex)}
              />
            </div>
          )
        }
      </div>
    </div>
  })