import React, { useEffect, useState, useRef } from 'react'
import lightTheme from './light.module.scss'
import darkTheme from './dark.module.scss'

const THEME_NAMES = {
  light: 'light',
  dark: 'dark',
}

const themes = {
  light: lightTheme.variables,
  dark: darkTheme.variables,
}

const defaultState = {
  theme: 'light',
  updateTheme: () => { console.warn('updateTheme called, but no implementation.') },
  getCSSVariable: () => {
    console.warn('getCSSVariable called, but no implementation. ')
    return ''
  },
}

const ThemeContext = React.createContext(defaultState)

const initialTheme = localStorage.getItem('theme') || defaultState.theme

const ThemeProvider = ({ children }) => {
  const [theme, updateTheme] = useState(initialTheme)
  const ref = useRef(null)

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  const getCSSVariable = variableName => {
    if (ref && ref.current) {
      return getComputedStyle(ref.current, null).getPropertyValue(variableName)
    }

    return ''
  }

  return (
    <ThemeContext.Provider value={{ theme, updateTheme, getCSSVariable }}>
      <ThemeContext.Consumer>
        {({ theme }) => (
          <div
            ref={ref}
            className={(theme && themes[theme]) || themes[defaultState.theme]}
            style={{ backgroundColor: 'var(--background-color)', minHeight: '100%', height: '100%' }}
          >
            {children}
          </div>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  )
}

export default ThemeContext
export { ThemeProvider, THEME_NAMES }
