import React, { useState, useEffect } from 'react'
import { Popover, TextField } from '@mui/material'
import styles from './SearchableDropdown.module.scss'
import { SearchableDropdown } from '../../testIds'


const Content = ({ list, onClick, children, testid = '' }) =>
  <div className={styles.popover} data-testid={testid}>
    {children}
    {
      list.map((item, index) =>
        <a text={item} key={`${item}${index}`} onClick={onClick} className={styles.item} data-testid={testid + '-' + index}>
          {item}
        </a>
      )
    }
  </div>

// testIds: pass a prefix to the component (ie. ar-)
// container: ar-container
// popover: ar-popover
// each option will follow: ar-popover-0, ar-popover-1, ar-popover-2, etc....
export default ({ selectedValue, list, handleChange, searchable, testidprefix = '' }) => {
  // ripple effect stuff
  const [coords, setCoords] = useState({ x: -1, y: -1 })
  const [isRippling, setIsRippling] = useState(false)


  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true)
      setTimeout(() => setIsRippling(false), 300)
    }
    else setIsRippling(false)
  }, [coords])

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 })
  }, [isRippling])

  // functionality stuff
  const [anchorEl, setAnchorEl] = useState(false)
  const [filter, setFilter] = useState('')

  const handleClose = () => {
    if (searchable) setFilter('')
    setAnchorEl(null)
  }

  const handleOpen = event => {
    if (searchable) setFilter('')
    setAnchorEl(event.currentTarget)
  }

  const onSelectOption = e => { handleChange(e.target.text); setAnchorEl(null) }


  const PopperContent = searchable
    ?
    <Content list={list.filter(item => item.toLowerCase().includes(filter.toLowerCase()))} onClick={onSelectOption} testid={testidprefix + SearchableDropdown[1]}>
      <TextField autoFocus value={filter} onChange={e => setFilter(e.target.value)} placeholder={'Type to filter...'} />
    </Content>
    :
    <Content list={list} onClick={onSelectOption} testid={testidprefix + SearchableDropdown[1]} />

  return <>
    <div
      data-testid={testidprefix + SearchableDropdown[0]}
      className={styles.button}
      onClick={e => {
        const rect = e.target.getBoundingClientRect()
        setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top })
        handleOpen(e)
      }}
    >
      {selectedValue?.toString() ?? 'Select structure'}
      <div className={styles.buttonSymbol}>&#x25bc;</div>
      {isRippling ? (
        <span
          className={styles.ripple}
          style={{
            left: coords.x,
            top: coords.y
          }}
        />
      ) : (
        ''
      )}
    </div>

    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {PopperContent}
    </Popover>

  </>
}