import * as React from 'react'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { parseISO } from 'date-fns'

function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},

    nullable,
    year,
    yearOnChange
  } = props

  return <>
    <Button
      variant='outlined'
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{
        minWidth: 40,
        maxHeight: 25,
        border: '1px solid black',
        backgroundColor: 'white',
        textTransform: 'capitalize',
        color: 'blue',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }}
    >
      {label}
    </Button>
    {
      (nullable && !!year) &&
      <Button
        size={'small'}
        variant={'text'}
        onClick={() => yearOnChange(0)}
        sx={{
          backgroundColor: 'transparent',
          minWidth: 25,
          color: 'red'
        }}
      >
        X
      </Button>
    }
  </>
}


export default ({ year, yearOnChange, nullable, testid }) => {
  const [value, setValue] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    {/* <Stack spacing={1}> */}
    <DatePicker
      data-testid={testid}
      views={['year']}
      slots={{ field: ButtonField }}
      slotProps={{ field: { setOpen, nullable, year, yearOnChange } }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}

      label={year || 'none'}
      value={parseISO(year + '')}
      onChange={(newValue) => yearOnChange(newValue)}
    />
    {/* </Stack> */}
  </LocalizationProvider>
}
