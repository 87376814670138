import { connect } from 'react-redux'
import styles from './Toggle.module.scss'
import * as AnnualRecommendationActions from '../../store/annualRecommendation'



const Toggle = ({ onClick, text, size = 34, value }) => {
  if (size !== 34) {
    document.querySelector(':root').style.setProperty('--toggle-height', `${size}px`)
    document.querySelector(':root').style.setProperty('--inner-size', `${size * 0.765}px`)
    document.querySelector(':root').style.setProperty('--toggle-width', `${size * 1.765}px`)
    document.querySelector(':root').style.setProperty('--toggle-padding', `${size * 0.125}px`)
  }
  return <div className={styles.container}>
    <label className={styles.switch} >
      <input type="checkbox" className={styles.input} defaultChecked={value} onChange={onClick} />
      <span className={styles.slider} />
    </label>
    {
      text &&
      <div className={value ? styles.enabledText : styles.disabledText}>{text}</div>
    }
  </div>
}



export const FrontMonthExclusionToggle = connect(state => ({
  exclusion: state.annualRecommendation.frontMonthExclusion
}), {
  toggleFrontMonthExclusion: AnnualRecommendationActions.toggleFrontMonthExclusion
})(
  ({
    exclusion, toggleFrontMonthExclusion
  }) =>
    <Toggle onClick={toggleFrontMonthExclusion} value={exclusion} text={'Exclude Front Month (expiry <= 31 days from now)'} size={26} />
)
