import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import styles from './AccordionPage.module.scss'


export default ({ accordionCustomStyling, summaryContent, detailsContent, pageContent, testId = '' }) => {
  const [expanded, setExpanded] = useState(true)
  const openAccordion = () => setExpanded(true)
  const closeAccordion = () => setExpanded(false)


  return <>
    <Accordion
      elevation={8}
      style={accordionCustomStyling ?? { display: 'flex', flexDirection: 'column-reverse', marginBottom: 20 }}
      onChange={() => setExpanded(!expanded)}
      expanded={expanded}
      data-testid={testId}
    >
      <AccordionSummary>
        {summaryContent ??
          <div className={styles.accordionOpener}>
            [Hide/Show Parameters]
          </div>
        }
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        {detailsContent && detailsContent(openAccordion, closeAccordion)}
      </AccordionDetails>
    </Accordion>

    {pageContent && pageContent(openAccordion)}
  </>
}