import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const LoadingIndicator = ({ children, ready, reason, testid, ...otherFlags }) =>
  (ready && Object.values(otherFlags).every(flag => Boolean(flag) === true))
    ?
    children
    :
    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', marginTop: '10%', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress />
      <p data-testid={testid}>{reason || 'Loading... Please wait...'}</p>
    </Box>
    
export default LoadingIndicator


export const ConnectedLoadingIndicator = connect(state => ({
  loading: state.application.loading
}))(({
  loading, children, loadingKeys = [], testid, ...otherFlags
}) => {
  const ready = Object.keys(loading).map(key => loadingKeys.includes(key)).every(val => val === false)
  const reason = loading[loadingKeys[0]] || Object.values(loading)[0]
  return <LoadingIndicator ready={ready} reason={reason} {...otherFlags} testid={testid}>
    {children}
  </LoadingIndicator>
}
)

/*
 Usage: 
  
  <ConnectedLoadingIndicator loadingKeys={['first', 'second']} testid={testIds.SomeTestId[2]}>
    <SomeComponent>
  </ConnectedLoadingIndicator>

  loading keys is a list of strings (exported from config.js as loadingReasons) which are checked against the loading object.
  If loading object has a key which matches an element from loadingKeys, it is considered to still be loading. 
  Once no keys are found, it is not listening to any of the other parts of the app which may be loading. 

  In the above example, when loading object has "first" or "second" as keys, the SomeComponent is not yet ready to be rendered. 
  The redux action relating to "first" and "second" should call addLoadingReason and removeLoadingReason at the start and end of the function, respectively. 


  <LoadingIndicator> is also exported which is less advanced and not connected. Just pass a boolean to the ready prop (and optionally, any other arbitrary boolean flags you may desire).

  TestId: self explanatory. It passes it into data-testid
*/